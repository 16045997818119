/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const alertHistoryByComid = /* GraphQL */ `
  query AlertHistoryByComid(
    $comid: String
    $filter: ModelalertHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    alertHistoryByComid(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        comid_type
        createdAt
        id
        pkey
        ruleid
        time
        type
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const alertHistoryByComidType = /* GraphQL */ `
  query AlertHistoryByComidType(
    $comid_type: String
    $filter: ModelalertHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    alertHistoryByComidType(
      comid_type: $comid_type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        comid_type
        createdAt
        id
        pkey
        ruleid
        time
        type
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const alertHistoryByPkey = /* GraphQL */ `
  query AlertHistoryByPkey(
    $filter: ModelalertHistoryFilterInput
    $limit: Int
    $nextToken: String
    $pkey: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    alertHistoryByPkey(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pkey: $pkey
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        comid_type
        createdAt
        id
        pkey
        ruleid
        time
        type
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const alertHistoryByRuleid = /* GraphQL */ `
  query AlertHistoryByRuleid(
    $filter: ModelalertHistoryFilterInput
    $limit: Int
    $nextToken: String
    $ruleid: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    alertHistoryByRuleid(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ruleid: $ruleid
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        comid_type
        createdAt
        id
        pkey
        ruleid
        time
        type
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const alertHistoryByType = /* GraphQL */ `
  query AlertHistoryByType(
    $filter: ModelalertHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
    $type: String
  ) {
    alertHistoryByType(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
      type: $type
    ) {
      items {
        comid
        comid_type
        createdAt
        id
        pkey
        ruleid
        time
        type
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const alertRuleByComid = /* GraphQL */ `
  query AlertRuleByComid(
    $comid: String
    $filter: ModelalertRuleFilterInput
    $is_active: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    alertRuleByComid(
      comid: $comid
      filter: $filter
      is_active: $is_active
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        comid
        createdAt
        id
        interval_in_seconds
        is_active
        name
        notifications
        rule
        time
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const deviceAlgorithmByCategoryAndName = /* GraphQL */ `
  query DeviceAlgorithmByCategoryAndName(
    $category: String
    $filter: ModeldeviceAlgorithmFilterInput
    $limit: Int
    $name: ModelStringKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    deviceAlgorithmByCategoryAndName(
      category: $category
      filter: $filter
      limit: $limit
      name: $name
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        algorithm
        category
        comids
        createdAt
        id
        name
        time
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const deviceAlgorithmByTypeAndLastUpdateTime = /* GraphQL */ `
  query DeviceAlgorithmByTypeAndLastUpdateTime(
    $filter: ModeldeviceAlgorithmFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
    $type: String
  ) {
    deviceAlgorithmByTypeAndLastUpdateTime(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
      type: $type
    ) {
      items {
        algorithm
        category
        comids
        createdAt
        id
        name
        time
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const deviceAlgorithmByTypeAndName = /* GraphQL */ `
  query DeviceAlgorithmByTypeAndName(
    $filter: ModeldeviceAlgorithmFilterInput
    $limit: Int
    $name: ModelStringKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String
  ) {
    deviceAlgorithmByTypeAndName(
      filter: $filter
      limit: $limit
      name: $name
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        algorithm
        category
        comids
        createdAt
        id
        name
        time
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const deviceHistoricalsByComid = /* GraphQL */ `
  query DeviceHistoricalsByComid(
    $comid: String
    $filter: ModeldeviceHistoricalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    deviceHistoricalsByComid(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        alerts
        comid
        createdAt
        id
        time
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const devicesByComid = /* GraphQL */ `
  query DevicesByComid(
    $comid: String
    $filter: ModeldeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    devicesByComid(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alg_c_interval_in_seconds
        alg_c_is_active
        alg_c_last_cal_status
        alg_c_uuid
        alg_p_interval_in_seconds
        alg_p_is_active
        alg_p_last_cal_status
        alg_p_last_cal_time
        alg_p_uuid
        category
        comid
        controllable
        createdAt
        device_name_jp
        deviceid
        flow
        items
        manufacturer
        meta_data
        placeid
        position
        protocols
        updatedAt
      }
      nextToken
    }
  }
`;
export const devicesByControllable = /* GraphQL */ `
  query DevicesByControllable(
    $comid: ModelStringKeyConditionInput
    $controllable: Int
    $filter: ModeldeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    devicesByControllable(
      comid: $comid
      controllable: $controllable
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alg_c_interval_in_seconds
        alg_c_is_active
        alg_c_last_cal_status
        alg_c_uuid
        alg_p_interval_in_seconds
        alg_p_is_active
        alg_p_last_cal_status
        alg_p_last_cal_time
        alg_p_uuid
        category
        comid
        controllable
        createdAt
        device_name_jp
        deviceid
        flow
        items
        manufacturer
        meta_data
        placeid
        position
        protocols
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlertHistory = /* GraphQL */ `
  query GetAlertHistory($id: ID!) {
    getAlertHistory(id: $id) {
      comid
      comid_type
      createdAt
      id
      pkey
      ruleid
      time
      type
      updatedAt
      values
    }
  }
`;
export const getAlertRule = /* GraphQL */ `
  query GetAlertRule($id: ID!) {
    getAlertRule(id: $id) {
      comid
      createdAt
      id
      interval_in_seconds
      is_active
      name
      notifications
      rule
      time
      type
      updatedAt
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($comid: String!, $placeid: String!) {
    getDevice(comid: $comid, placeid: $placeid) {
      alg_c_interval_in_seconds
      alg_c_is_active
      alg_c_last_cal_status
      alg_c_uuid
      alg_p_interval_in_seconds
      alg_p_is_active
      alg_p_last_cal_status
      alg_p_last_cal_time
      alg_p_uuid
      category
      comid
      controllable
      createdAt
      device_name_jp
      deviceid
      flow
      items
      manufacturer
      meta_data
      placeid
      position
      protocols
      updatedAt
    }
  }
`;
export const getDeviceAlgorithm = /* GraphQL */ `
  query GetDeviceAlgorithm($id: ID!) {
    getDeviceAlgorithm(id: $id) {
      algorithm
      category
      comids
      createdAt
      id
      name
      time
      type
      updatedAt
    }
  }
`;
export const getDeviceCtrlValue = /* GraphQL */ `
  query GetDeviceCtrlValue($comid: String!, $time: AWSTimestamp!) {
    getDeviceCtrlValue(comid: $comid, time: $time) {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const getDeviceHistorical = /* GraphQL */ `
  query GetDeviceHistorical($id: ID!) {
    getDeviceHistorical(id: $id) {
      alerts
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const getDevicePlanValue = /* GraphQL */ `
  query GetDevicePlanValue($comid: String!, $time: AWSTimestamp!) {
    getDevicePlanValue(comid: $comid, time: $time) {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const getOperateLog = /* GraphQL */ `
  query GetOperateLog($id: ID!) {
    getOperateLog(id: $id) {
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const listAlertHistorys = /* GraphQL */ `
  query ListAlertHistorys(
    $filter: ModelalertHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlertHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        comid
        comid_type
        createdAt
        id
        pkey
        ruleid
        time
        type
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const listAlertRules = /* GraphQL */ `
  query ListAlertRules(
    $filter: ModelalertRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlertRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        comid
        createdAt
        id
        interval_in_seconds
        is_active
        name
        notifications
        rule
        time
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDeviceAlgorithms = /* GraphQL */ `
  query ListDeviceAlgorithms(
    $filter: ModeldeviceAlgorithmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceAlgorithms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        algorithm
        category
        comids
        createdAt
        id
        name
        time
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDeviceCtrlValues = /* GraphQL */ `
  query ListDeviceCtrlValues(
    $comid: String
    $filter: ModeldeviceCtrlValueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    listDeviceCtrlValues(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        createdAt
        time
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const listDeviceHistoricals = /* GraphQL */ `
  query ListDeviceHistoricals(
    $filter: ModeldeviceHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceHistoricals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        alerts
        comid
        createdAt
        id
        time
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const listDevicePlanValues = /* GraphQL */ `
  query ListDevicePlanValues(
    $comid: String
    $filter: ModeldevicePlanValueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    listDevicePlanValues(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        createdAt
        time
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $comid: ModelStringKeyConditionInput
    $filter: ModeldeviceFilterInput
    $limit: Int
    $nextToken: String
    $placeid: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      placeid: $placeid
      sortDirection: $sortDirection
    ) {
      items {
        alg_c_interval_in_seconds
        alg_c_is_active
        alg_c_last_cal_status
        alg_c_uuid
        alg_p_interval_in_seconds
        alg_p_is_active
        alg_p_last_cal_status
        alg_p_last_cal_time
        alg_p_uuid
        category
        comid
        controllable
        createdAt
        device_name_jp
        deviceid
        flow
        items
        manufacturer
        meta_data
        placeid
        position
        protocols
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOperateLogs = /* GraphQL */ `
  query ListOperateLogs(
    $filter: ModeloperateLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperateLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        comid
        createdAt
        id
        time
        updatedAt
        values
      }
      nextToken
    }
  }
`;
export const operateLogByComid = /* GraphQL */ `
  query OperateLogByComid(
    $comid: String
    $filter: ModeloperateLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $time: ModelTimeKeyConditionInput
  ) {
    operateLogByComid(
      comid: $comid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      time: $time
    ) {
      items {
        comid
        createdAt
        id
        time
        updatedAt
        values
      }
      nextToken
    }
  }
`;

/**
 * 展示用UI画面itemの表示情報
 * 1.itemのcomid
 * 2.itemの項目名
 * 3.itemの単位（空文字列：'kW'を使用する）
 * 4.itemのtimestamp項目名（空文字列：'createAt'を使用する）
 */
export interface displayUIitem {
  comid: string
  item: string
  time: string
}

/**
 * 展示用UIで切り替える項目セット
 * 1.研究所全体
 * 2.事務棟
 * 3.FC実験棟
 */
export const DISPLAYUI_ITEMS: { [key: string]: { elements: displayUIitem[] }[] } = Object.freeze({
  // 研究所全体
  allBld: [
    // 太陽光発電
    {
      elements: [
        { comid: '001_PV001_1', item: 'instant_power_total', time: '' },
        { comid: '001_PV002_1', item: 'instant_power_total', time: '' },
      ],
    },
    // 受電
    {
      elements: [{ comid: '001_EL001_1', item: 'power_instant_pulse_1min', time: '' }],
    },
    // 水素製造
    {
      elements: [{ comid: '001_WE001_1', item: 'power_total', time: '' }],
    },
    // 蓄電池
    {
      elements: [{ comid: '001_BT001_1', item: 'battery_instant_power', time: 'battery_instant_power_timestamp' }],
    },
  ],
  // 事務棟
  jimuBld: [
    // 太陽光発電
    {
      elements: [{ comid: '001_PV001_1', item: 'instant_power_total', time: '' }],
    },
    // 受電
    {
      elements: [
        { comid: '001_EL002_1', item: 'Staff Office Bldg A', time: '' },
        { comid: '001_EL002_1', item: 'Staff Office Bldg B', time: '' },
        { comid: '001_EL002_1', item: 'Staff Office Bldg C', time: '' },
      ],
    },
    // 水素製造
    {
      elements: [],
    },
    // 蓄電池
    {
      elements: [],
    },
  ],
  // FC実験棟
  fcBld: [
    // 太陽光発電
    {
      elements: [{ comid: '001_PV002_1', item: 'instant_power_total', time: '' }],
    },
    // 受電
    {
      elements: [{ comid: '001_BT001_2', item: 'SiteMeter_Power_3PH', time: 'timestamp' }],
    },
    // 水素製造
    {
      elements: [{ comid: '001_WE001_1', item: 'power_total', time: '' }],
    },
    // 蓄電池
    {
      elements: [{ comid: '001_BT001_2', item: 'BatteryMeter_Power_3PH', time: 'timestamp' }],
    },
  ],
})

/**
 * 展示用UI表示する項目情報
 *
 * index: 瞬時値アイコンの表示する順番
 * jpName: 項目の日本語名称
 * icon.name: 瞬時値アイコンの名称
 * icon.color: 瞬時値アイコンの色
 * indexOfLegend: 過去グラフに表示する順番
 */
export const DISPLAYUI_ICONS = Object.freeze([
  { jpName: '使用電力', icon: { name: 'mdi-home-city', color: '#d1d491' }, indexOfLegend: 0 }, // 黄色
  { jpName: '太陽光発電', icon: { name: 'mdi-solar-panel-large', color: '#ff375f' }, indexOfLegend: 1 }, // 赤色
  { jpName: '受電', icon: { name: 'mdi-transmission-tower', color: '#98989d' }, indexOfLegend: 4 }, // 灰色
  { jpName: '水素製造', icon: { name: 'mdi-car-battery', color: '#0a84ff' }, indexOfLegend: 3 }, // 青色
  { jpName: '蓄電池', icon: { name: 'mdi-battery-charging', color: '#ff950a' }, indexOfLegend: 2 }, // 橙色
])

/**
 * 展示用UI専用データ取得対象(historical)
 * items：絞り込む項目
 * divide：周期の短いデータを間引くフラグ
 * latestOnly：最新値のみ検索するフラグ
 */
export const DISPLAYUI_COMIDS_OF_ACTUAL_VALUE = Object.freeze([
  // 展示用UI左側の瞬時グラフとヒストリカルグラフのデータ取得対象
  {
    comid: '001_PV001_1',
    items: ['instant_power_total'],
    divide: false,
    latestOnly: false,
  },
  {
    comid: '001_PV002_1',
    items: ['instant_power_total'],
    divide: false,
    latestOnly: false,
  },
  {
    comid: '001_EL001_1',
    items: ['power_instant_pulse_1min'],
    divide: false,
    latestOnly: false,
  },
  {
    comid: '001_EL002_1',
    items: ['Staff Office Bldg A', 'Staff Office Bldg B', 'Staff Office Bldg C'],
    divide: false,
    latestOnly: false,
  },
  {
    comid: '001_WE001_1',
    items: ['power_total'],
    divide: true,
    latestOnly: false,
  },
  {
    comid: '001_BT001_1',
    items: ['battery_instant_power', 'battery_instant_power_timestamp'],
    divide: false,
    latestOnly: false,
  },
  {
    comid: '001_BT001_2',
    items: ['SiteMeter_Power_3PH', 'BatteryMeter_Power_3PH', 'timestamp'],
    divide: true,
    latestOnly: false,
  },
  // 展示用UI右側の天気情報(現在)のデータ取得対象
  {
    comid: '001_WD002_1',
    items: ['airtmp', 'prcint'],
    divide: false,
    latestOnly: true,
  },
])

/**
 * 展示用UI専用データ取得対象(planValue)
 */
export const DISPLAYUI_COMIDS_OF_PLAN_VALUE = Object.freeze([
  // 展示用UI右側の天気情報(未来)のデータ取得対象
  {
    comid: '001_WD003_1',
    items: ['airtmp', 'wx'],
  },
])

/**
 * 天気アイコン
 */
export const WEATHER_ICON = Object.freeze({
  '100': {
    name_jp: '晴れ',
    name: 'mdi-weather-partly-cloudy',
    color: 'ffda29',
  },
  '200': {
    name_jp: '曇り',
    name: 'mdi-cloud',
    color: '#78bfff',
  },
  '300': {
    name_jp: '雨',
    name: 'mdi-umbrella-outline',
    color: '#78bfff',
  },
  '400': {
    name_jp: '雪',
    name: 'mdi-snowflake',
    color: '#f0f0f0',
  },
  '430': {
    name_jp: 'みぞれ',
    name: 'mdi-weather-snowy-rainy',
    color: '#78bfff',
  },
  '500': {
    name_jp: '快晴',
    name: 'mdi-white-balance-sunny',
    color: '#fb404b',
  },
  '600': {
    name_jp: '薄曇り',
    name: 'mdi-weather-cloudy',
    color: '#78bfff',
  },
  '-999': {
    name_jp: '欠測など天候不明',
    name: 'mdi-alert-circle-outline',
    color: '#ffa534',
  },
})

/**
 * 検索対象テーブル
 */
export const DYNAMODB_TABLE = 'deviceHistorical-cszyjsqb5zegxk43n2tgx6aoji-preprod'

/**
 * RDS検索対象
 */
export const RDS_PARAMETERS = Object.freeze([
  [
    {
      comid: '001_PV001_1',
      itemid: ['instant_power_total'],
    },
    {
      comid: '001_PV002_1',
      itemid: ['instant_power_total'],
    },
    {
      comid: '001_BT001_1',
      itemid: ['battery_instant_power'],
    },
    {
      comid: '001_WE001_1',
      itemid: ['power_total'],
    },
    {
      comid: '001_EL001_1',
      itemid: ['power_instant_pulse_1min'],
    },
  ],
  [
    {
      comid: '001_PV001_1',
      itemid: ['instant_power_total'],
    },
    {
      comid: '001_EL002_1',
      itemid: ['Staff Office Bldg A', 'Staff Office Bldg B', 'Staff Office Bldg C'],
    },
  ],
  [
    {
      comid: '001_PV002_1',
      itemid: ['instant_power_total'],
    },
    {
      comid: '001_BT001_2',
      itemid: ['SiteMeter_Power_3PH', 'BatteryMeter_Power_3PH'],
    },
    {
      comid: '001_WE001_1',
      itemid: ['power_total'],
    },
  ],
])

/**
 * グラフ表示情報
 */
export const SERIES_INFO = Object.freeze([
  [
    {
      series: '太陽光発電',
      elements: [
        { comid: '001_PV001_1', itemid: 'instant_power_total' },
        { comid: '001_PV002_1', itemid: 'instant_power_total' },
      ],
    },
    {
      series: '蓄電池',
      elements: [{ comid: '001_BT001_1', itemid: 'battery_instant_power' }],
    },
    {
      series: '水素製造',
      elements: [{ comid: '001_WE001_1', itemid: 'power_total' }],
    },
    {
      series: '受電',
      elements: [{ comid: '001_EL001_1', itemid: 'power_instant_pulse_1min' }],
    },
  ],
  [
    {
      series: '太陽光発電',
      elements: [{ comid: '001_PV001_1', itemid: 'instant_power_total' }],
    },
    {
      series: '蓄電池',
      elements: [],
    },
    {
      series: '水素製造',
      elements: [],
    },
    {
      series: '受電',
      elements: [
        { comid: '001_EL002_1', itemid: 'Staff Office Bldg A' },
        { comid: '001_EL002_1', itemid: 'Staff Office Bldg B' },
        { comid: '001_EL002_1', itemid: 'Staff Office Bldg C' },
      ],
    },
  ],
  [
    {
      series: '太陽光発電',
      elements: [{ comid: '001_PV002_1', itemid: 'instant_power_total' }],
    },
    {
      series: '蓄電池',
      elements: [{ comid: '001_BT001_2', itemid: 'BatteryMeter_Power_3PH' }],
    },
    {
      series: '水素製造',
      elements: [{ comid: '001_WE001_1', itemid: 'power_total' }],
    },
    {
      series: '受電',
      elements: [{ comid: '001_BT001_2', itemid: 'SiteMeter_Power_3PH' }],
    },
  ],
])

import { API, graphqlOperation } from 'aws-amplify'
import Observable from 'zen-observable-ts'
import * as subscriptions from '@/graphql/subscriptions'
import * as queries from '@/graphql/queries'
import * as SchemaType from '@/store/amplify/extraSchemaTypes'
import { OnCreateDevicePlanValueSubscription, ListDevicePlanValuesQueryVariables, ModelSortDirection } from '@/API'

/** デフォルト取得行数上限 */
const DEFAULT_PAGING_LIMITS = 1

/** AppSyncからプッシュされたデータの型定義 */
export type onCreateDevicePlanValueSubscriptionMessage = {
  /** プロバイダー（metadata）: AWSAppSyncRealTimeProvider */
  provider: any
  /** プッシュされたデータ */
  value: { data: OnCreateDevicePlanValueSubscription }
}

/** サブスクライブメソッドの型定義 */
export type subscribeToDevicePlanValueOnCreateCBFunction = (data: OnCreateDevicePlanValueSubscription) => void

/**
 * 計画値テーブルの新規追加することをサブスクライブする
 *
 * 例:
 * const subscription = subscribeDevicePlanValue( (incomingNewData) => void );
 * subscription.unscribe();
 *
 */
const subscribeToDevicePlanValueOnCreate = (callbackFunction: subscribeToDevicePlanValueOnCreateCBFunction) => {
  const subscription = (<Observable<Object>>(
    API.graphql(graphqlOperation(subscriptions.onCreateDevicePlanValue))
  )).subscribe({
    next: ({ value: { data } }: onCreateDevicePlanValueSubscriptionMessage) => {
      callbackFunction(data)
    },
  })
  return subscription
}

/**
 * 計画値を検索する
 *
 * 検索条件：comid, unix_time[]
 */
const findDevicePlanValuesByComIDAndTime = async (userInput: findDevicePlanValueByComIDAndTimeUserInput) => {
  const payload: ListDevicePlanValuesQueryVariables = {
    comid: userInput.comid,
    time: { between: userInput.time },
    sortDirection: userInput.sortDirection,
    filter: null,
    limit: userInput.limit || DEFAULT_PAGING_LIMITS,
    /** null specified by the payload interface */
    nextToken: userInput.nextToken || null,
  }

  return await API.graphql(graphqlOperation(queries.listDevicePlanValues, payload))
}

/** 検索条件の型定義 */
export type findDevicePlanValueByComIDAndTimeUserInput = {
  /** 機器comid */
  comid: SchemaType.QLComid
  /** 検索期間(Unix Time) */
  time: number[]
  /** 取得行数上限 */
  limit?: number
  /** トークン */
  nextToken?: SchemaType.QLNextToken
  sortDirection?: ModelSortDirection
}

export default {
  subscribeToDevicePlanValueOnCreate,
  findDevicePlanValuesByComIDAndTime,
}

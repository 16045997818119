/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:88d3154d-20b7-4a10-a632-bce162b04064",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ROHVzlfTM",
    "aws_user_pools_web_client_id": "40f46orje434mt6nhmiu1grjbe",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://tt6aiawgizgzvddauqb4zrzcdy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bkwy7j4zjfc2fnewovjysotjey"
};


export default awsmobile;

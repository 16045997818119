/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelalertHistoryConditionInput = {
  and?: Array< ModelalertHistoryConditionInput | null > | null,
  comid?: ModelStringInput | null,
  comid_type?: ModelStringInput | null,
  not?: ModelalertHistoryConditionInput | null,
  or?: Array< ModelalertHistoryConditionInput | null > | null,
  pkey?: ModelStringInput | null,
  ruleid?: ModelStringInput | null,
  time?: ModelTimeInput | null,
  type?: ModelStringInput | null,
  values?: ModelStringInput | null,
};

export type ModelStringInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}


export type ModelSizeInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type ModelTimeInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type CreateAlertHistoryInput = {
  comid: string,
  comid_type: string,
  id?: string | null,
  pkey: string,
  ruleid: string,
  time: number,
  type: string,
  values: string,
};

export type ModelalertRuleConditionInput = {
  and?: Array< ModelalertRuleConditionInput | null > | null,
  comid?: ModelStringInput | null,
  interval_in_seconds?: ModelIntInput | null,
  is_active?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelalertRuleConditionInput | null,
  notifications?: ModelStringInput | null,
  or?: Array< ModelalertRuleConditionInput | null > | null,
  rule?: ModelStringInput | null,
  time?: ModelTimeInput | null,
  type?: ModelStringInput | null,
};

export type ModelIntInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type CreateAlertRuleInput = {
  comid: string,
  id?: string | null,
  interval_in_seconds: number,
  is_active: string,
  name: string,
  notifications: string,
  rule: string,
  time: number,
  type: string,
};

export type ModeldeviceConditionInput = {
  alg_c_interval_in_seconds?: ModelIntInput | null,
  alg_c_is_active?: ModelBooleanInput | null,
  alg_c_last_cal_status?: ModelStringInput | null,
  alg_c_uuid?: ModelIDInput | null,
  alg_p_interval_in_seconds?: ModelIntInput | null,
  alg_p_is_active?: ModelBooleanInput | null,
  alg_p_last_cal_status?: ModelStringInput | null,
  alg_p_last_cal_time?: ModelTimeInput | null,
  alg_p_uuid?: ModelIDInput | null,
  and?: Array< ModeldeviceConditionInput | null > | null,
  category?: ModelStringInput | null,
  controllable?: ModelIntInput | null,
  device_name_jp?: ModelStringInput | null,
  deviceid?: ModelStringInput | null,
  flow?: ModelStringInput | null,
  items?: ModelStringInput | null,
  manufacturer?: ModelStringInput | null,
  meta_data?: ModelStringInput | null,
  not?: ModeldeviceConditionInput | null,
  or?: Array< ModeldeviceConditionInput | null > | null,
  position?: ModelStringInput | null,
  protocols?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  eq?: boolean | null,
  ne?: boolean | null,
};

export type ModelIDInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type CreateDeviceInput = {
  alg_c_interval_in_seconds?: number | null,
  alg_c_is_active?: boolean | null,
  alg_c_last_cal_status?: string | null,
  alg_c_uuid?: string | null,
  alg_p_interval_in_seconds?: number | null,
  alg_p_is_active?: boolean | null,
  alg_p_last_cal_status?: string | null,
  alg_p_last_cal_time?: number | null,
  alg_p_uuid?: string | null,
  category: string,
  comid: string,
  controllable: number,
  device_name_jp?: string | null,
  deviceid: string,
  flow?: string | null,
  items: string,
  manufacturer?: string | null,
  meta_data?: string | null,
  placeid: string,
  position?: string | null,
  protocols?: string | null,
};

export type ModeldeviceAlgorithmConditionInput = {
  algorithm?: ModelStringInput | null,
  and?: Array< ModeldeviceAlgorithmConditionInput | null > | null,
  category?: ModelStringInput | null,
  comids?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModeldeviceAlgorithmConditionInput | null,
  or?: Array< ModeldeviceAlgorithmConditionInput | null > | null,
  time?: ModelTimeInput | null,
  type?: ModelStringInput | null,
};

export type CreateDeviceAlgorithmInput = {
  algorithm?: string | null,
  category: string,
  comids?: string | null,
  id?: string | null,
  name: string,
  time: number,
  type: string,
};

export type ModeldeviceCtrlValueConditionInput = {
  and?: Array< ModeldeviceCtrlValueConditionInput | null > | null,
  not?: ModeldeviceCtrlValueConditionInput | null,
  or?: Array< ModeldeviceCtrlValueConditionInput | null > | null,
  values?: ModelStringInput | null,
};

export type CreateDeviceCtrlValueInput = {
  comid: string,
  time: number,
  values: string,
};

export type ModeldeviceHistoricalConditionInput = {
  alerts?: ModelStringInput | null,
  and?: Array< ModeldeviceHistoricalConditionInput | null > | null,
  comid?: ModelStringInput | null,
  not?: ModeldeviceHistoricalConditionInput | null,
  or?: Array< ModeldeviceHistoricalConditionInput | null > | null,
  time?: ModelTimeInput | null,
  values?: ModelStringInput | null,
};

export type CreateDeviceHistoricalInput = {
  alerts?: string | null,
  comid: string,
  id?: string | null,
  time: number,
  values: string,
};

export type ModeldevicePlanValueConditionInput = {
  and?: Array< ModeldevicePlanValueConditionInput | null > | null,
  not?: ModeldevicePlanValueConditionInput | null,
  or?: Array< ModeldevicePlanValueConditionInput | null > | null,
  values?: ModelStringInput | null,
};

export type CreateDevicePlanValueInput = {
  comid: string,
  time: number,
  values: string,
};

export type ModeloperateLogConditionInput = {
  and?: Array< ModeloperateLogConditionInput | null > | null,
  comid?: ModelStringInput | null,
  not?: ModeloperateLogConditionInput | null,
  or?: Array< ModeloperateLogConditionInput | null > | null,
  time?: ModelTimeInput | null,
  values?: ModelStringInput | null,
};

export type CreateOperateLogInput = {
  comid: string,
  id?: string | null,
  time: number,
  values: string,
};

export type DeleteAlertHistoryInput = {
  id?: string | null,
};

export type DeleteAlertRuleInput = {
  id?: string | null,
};

export type DeleteDeviceInput = {
  comid: string,
  placeid: string,
};

export type DeleteDeviceAlgorithmInput = {
  id?: string | null,
};

export type DeleteDeviceCtrlValueInput = {
  comid: string,
  time: number,
};

export type DeleteDeviceHistoricalInput = {
  id?: string | null,
};

export type DeleteDevicePlanValueInput = {
  comid: string,
  time: number,
};

export type DeleteOperateLogInput = {
  id?: string | null,
};

export type UpdateAlertHistoryInput = {
  comid?: string | null,
  comid_type?: string | null,
  id: string,
  pkey?: string | null,
  ruleid?: string | null,
  time?: number | null,
  type?: string | null,
  values?: string | null,
};

export type UpdateAlertRuleInput = {
  comid?: string | null,
  id: string,
  interval_in_seconds?: number | null,
  is_active?: string | null,
  name?: string | null,
  notifications?: string | null,
  rule?: string | null,
  time?: number | null,
  type?: string | null,
};

export type UpdateDeviceInput = {
  alg_c_interval_in_seconds?: number | null,
  alg_c_is_active?: boolean | null,
  alg_c_last_cal_status?: string | null,
  alg_c_uuid?: string | null,
  alg_p_interval_in_seconds?: number | null,
  alg_p_is_active?: boolean | null,
  alg_p_last_cal_status?: string | null,
  alg_p_last_cal_time?: number | null,
  alg_p_uuid?: string | null,
  category?: string | null,
  comid: string,
  controllable?: number | null,
  device_name_jp?: string | null,
  deviceid?: string | null,
  flow?: string | null,
  items?: string | null,
  manufacturer?: string | null,
  meta_data?: string | null,
  placeid: string,
  position?: string | null,
  protocols?: string | null,
};

export type UpdateDeviceAlgorithmInput = {
  algorithm?: string | null,
  category?: string | null,
  comids?: string | null,
  id: string,
  name?: string | null,
  time?: number | null,
  type?: string | null,
};

export type UpdateDeviceCtrlValueInput = {
  comid: string,
  time: number,
  values?: string | null,
};

export type UpdateDeviceHistoricalInput = {
  alerts?: string | null,
  comid?: string | null,
  id: string,
  time?: number | null,
  values?: string | null,
};

export type UpdateDevicePlanValueInput = {
  comid: string,
  time: number,
  values?: string | null,
};

export type UpdateOperateLogInput = {
  comid?: string | null,
  id: string,
  time?: number | null,
  values?: string | null,
};

export type ModelalertHistoryFilterInput = {
  and?: Array< ModelalertHistoryFilterInput | null > | null,
  comid?: ModelStringInput | null,
  comid_type?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModelalertHistoryFilterInput | null,
  or?: Array< ModelalertHistoryFilterInput | null > | null,
  pkey?: ModelStringInput | null,
  ruleid?: ModelStringInput | null,
  time?: ModelTimeInput | null,
  type?: ModelStringInput | null,
  values?: ModelStringInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTimeKeyConditionInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
};

export type ModelalertRuleFilterInput = {
  and?: Array< ModelalertRuleFilterInput | null > | null,
  comid?: ModelStringInput | null,
  id?: ModelIDInput | null,
  interval_in_seconds?: ModelIntInput | null,
  is_active?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelalertRuleFilterInput | null,
  notifications?: ModelStringInput | null,
  or?: Array< ModelalertRuleFilterInput | null > | null,
  rule?: ModelStringInput | null,
  time?: ModelTimeInput | null,
  type?: ModelStringInput | null,
};

export type ModelStringKeyConditionInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
};

export type ModeldeviceAlgorithmFilterInput = {
  algorithm?: ModelStringInput | null,
  and?: Array< ModeldeviceAlgorithmFilterInput | null > | null,
  category?: ModelStringInput | null,
  comids?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  not?: ModeldeviceAlgorithmFilterInput | null,
  or?: Array< ModeldeviceAlgorithmFilterInput | null > | null,
  time?: ModelTimeInput | null,
  type?: ModelStringInput | null,
};

export type ModeldeviceHistoricalFilterInput = {
  alerts?: ModelStringInput | null,
  and?: Array< ModeldeviceHistoricalFilterInput | null > | null,
  comid?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModeldeviceHistoricalFilterInput | null,
  or?: Array< ModeldeviceHistoricalFilterInput | null > | null,
  time?: ModelTimeInput | null,
  values?: ModelStringInput | null,
};

export type ModeldeviceFilterInput = {
  alg_c_interval_in_seconds?: ModelIntInput | null,
  alg_c_is_active?: ModelBooleanInput | null,
  alg_c_last_cal_status?: ModelStringInput | null,
  alg_c_uuid?: ModelIDInput | null,
  alg_p_interval_in_seconds?: ModelIntInput | null,
  alg_p_is_active?: ModelBooleanInput | null,
  alg_p_last_cal_status?: ModelStringInput | null,
  alg_p_last_cal_time?: ModelTimeInput | null,
  alg_p_uuid?: ModelIDInput | null,
  and?: Array< ModeldeviceFilterInput | null > | null,
  category?: ModelStringInput | null,
  comid?: ModelStringInput | null,
  controllable?: ModelIntInput | null,
  device_name_jp?: ModelStringInput | null,
  deviceid?: ModelStringInput | null,
  flow?: ModelStringInput | null,
  items?: ModelStringInput | null,
  manufacturer?: ModelStringInput | null,
  meta_data?: ModelStringInput | null,
  not?: ModeldeviceFilterInput | null,
  or?: Array< ModeldeviceFilterInput | null > | null,
  placeid?: ModelStringInput | null,
  position?: ModelStringInput | null,
  protocols?: ModelStringInput | null,
};

export type ModeldeviceCtrlValueFilterInput = {
  and?: Array< ModeldeviceCtrlValueFilterInput | null > | null,
  comid?: ModelStringInput | null,
  not?: ModeldeviceCtrlValueFilterInput | null,
  or?: Array< ModeldeviceCtrlValueFilterInput | null > | null,
  time?: ModelTimeInput | null,
  values?: ModelStringInput | null,
};

export type ModeldevicePlanValueFilterInput = {
  and?: Array< ModeldevicePlanValueFilterInput | null > | null,
  comid?: ModelStringInput | null,
  not?: ModeldevicePlanValueFilterInput | null,
  or?: Array< ModeldevicePlanValueFilterInput | null > | null,
  time?: ModelTimeInput | null,
  values?: ModelStringInput | null,
};

export type ModeloperateLogFilterInput = {
  and?: Array< ModeloperateLogFilterInput | null > | null,
  comid?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModeloperateLogFilterInput | null,
  or?: Array< ModeloperateLogFilterInput | null > | null,
  time?: ModelTimeInput | null,
  values?: ModelStringInput | null,
};

export type CreateAlertHistoryMutationVariables = {
  condition?: ModelalertHistoryConditionInput | null,
  input: CreateAlertHistoryInput,
};

export type CreateAlertHistoryMutation = {
  createAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type CreateAlertRuleMutationVariables = {
  condition?: ModelalertRuleConditionInput | null,
  input: CreateAlertRuleInput,
};

export type CreateAlertRuleMutation = {
  createAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceMutationVariables = {
  condition?: ModeldeviceConditionInput | null,
  input: CreateDeviceInput,
};

export type CreateDeviceMutation = {
  createDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type CreateDeviceAlgorithmMutationVariables = {
  condition?: ModeldeviceAlgorithmConditionInput | null,
  input: CreateDeviceAlgorithmInput,
};

export type CreateDeviceAlgorithmMutation = {
  createDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceCtrlValueMutationVariables = {
  condition?: ModeldeviceCtrlValueConditionInput | null,
  input: CreateDeviceCtrlValueInput,
};

export type CreateDeviceCtrlValueMutation = {
  createDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type CreateDeviceHistoricalMutationVariables = {
  condition?: ModeldeviceHistoricalConditionInput | null,
  input: CreateDeviceHistoricalInput,
};

export type CreateDeviceHistoricalMutation = {
  createDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type CreateDevicePlanValueMutationVariables = {
  condition?: ModeldevicePlanValueConditionInput | null,
  input: CreateDevicePlanValueInput,
};

export type CreateDevicePlanValueMutation = {
  createDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type CreateOperateLogMutationVariables = {
  condition?: ModeloperateLogConditionInput | null,
  input: CreateOperateLogInput,
};

export type CreateOperateLogMutation = {
  createOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type DeleteAlertHistoryMutationVariables = {
  condition?: ModelalertHistoryConditionInput | null,
  input: DeleteAlertHistoryInput,
};

export type DeleteAlertHistoryMutation = {
  deleteAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type DeleteAlertRuleMutationVariables = {
  condition?: ModelalertRuleConditionInput | null,
  input: DeleteAlertRuleInput,
};

export type DeleteAlertRuleMutation = {
  deleteAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  condition?: ModeldeviceConditionInput | null,
  input: DeleteDeviceInput,
};

export type DeleteDeviceMutation = {
  deleteDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceAlgorithmMutationVariables = {
  condition?: ModeldeviceAlgorithmConditionInput | null,
  input: DeleteDeviceAlgorithmInput,
};

export type DeleteDeviceAlgorithmMutation = {
  deleteDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceCtrlValueMutationVariables = {
  condition?: ModeldeviceCtrlValueConditionInput | null,
  input: DeleteDeviceCtrlValueInput,
};

export type DeleteDeviceCtrlValueMutation = {
  deleteDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type DeleteDeviceHistoricalMutationVariables = {
  condition?: ModeldeviceHistoricalConditionInput | null,
  input: DeleteDeviceHistoricalInput,
};

export type DeleteDeviceHistoricalMutation = {
  deleteDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type DeleteDevicePlanValueMutationVariables = {
  condition?: ModeldevicePlanValueConditionInput | null,
  input: DeleteDevicePlanValueInput,
};

export type DeleteDevicePlanValueMutation = {
  deleteDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type DeleteOperateLogMutationVariables = {
  condition?: ModeloperateLogConditionInput | null,
  input: DeleteOperateLogInput,
};

export type DeleteOperateLogMutation = {
  deleteOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type UpdateAlertHistoryMutationVariables = {
  condition?: ModelalertHistoryConditionInput | null,
  input: UpdateAlertHistoryInput,
};

export type UpdateAlertHistoryMutation = {
  updateAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type UpdateAlertRuleMutationVariables = {
  condition?: ModelalertRuleConditionInput | null,
  input: UpdateAlertRuleInput,
};

export type UpdateAlertRuleMutation = {
  updateAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  condition?: ModeldeviceConditionInput | null,
  input: UpdateDeviceInput,
};

export type UpdateDeviceMutation = {
  updateDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceAlgorithmMutationVariables = {
  condition?: ModeldeviceAlgorithmConditionInput | null,
  input: UpdateDeviceAlgorithmInput,
};

export type UpdateDeviceAlgorithmMutation = {
  updateDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceCtrlValueMutationVariables = {
  condition?: ModeldeviceCtrlValueConditionInput | null,
  input: UpdateDeviceCtrlValueInput,
};

export type UpdateDeviceCtrlValueMutation = {
  updateDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type UpdateDeviceHistoricalMutationVariables = {
  condition?: ModeldeviceHistoricalConditionInput | null,
  input: UpdateDeviceHistoricalInput,
};

export type UpdateDeviceHistoricalMutation = {
  updateDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type UpdateDevicePlanValueMutationVariables = {
  condition?: ModeldevicePlanValueConditionInput | null,
  input: UpdateDevicePlanValueInput,
};

export type UpdateDevicePlanValueMutation = {
  updateDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type UpdateOperateLogMutationVariables = {
  condition?: ModeloperateLogConditionInput | null,
  input: UpdateOperateLogInput,
};

export type UpdateOperateLogMutation = {
  updateOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type AlertHistoryByComidQueryVariables = {
  comid?: string | null,
  filter?: ModelalertHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type AlertHistoryByComidQuery = {
  alertHistoryByComid:  {
    __typename: "ModelalertHistoryConnection",
    items:  Array< {
      __typename: "alertHistory",
      comid: string,
      comid_type: string,
      createdAt: string,
      id: string,
      pkey: string,
      ruleid: string,
      time: number,
      type: string,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AlertHistoryByComidTypeQueryVariables = {
  comid_type?: string | null,
  filter?: ModelalertHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type AlertHistoryByComidTypeQuery = {
  alertHistoryByComidType:  {
    __typename: "ModelalertHistoryConnection",
    items:  Array< {
      __typename: "alertHistory",
      comid: string,
      comid_type: string,
      createdAt: string,
      id: string,
      pkey: string,
      ruleid: string,
      time: number,
      type: string,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AlertHistoryByPkeyQueryVariables = {
  filter?: ModelalertHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  pkey?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type AlertHistoryByPkeyQuery = {
  alertHistoryByPkey:  {
    __typename: "ModelalertHistoryConnection",
    items:  Array< {
      __typename: "alertHistory",
      comid: string,
      comid_type: string,
      createdAt: string,
      id: string,
      pkey: string,
      ruleid: string,
      time: number,
      type: string,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AlertHistoryByRuleidQueryVariables = {
  filter?: ModelalertHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  ruleid?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type AlertHistoryByRuleidQuery = {
  alertHistoryByRuleid:  {
    __typename: "ModelalertHistoryConnection",
    items:  Array< {
      __typename: "alertHistory",
      comid: string,
      comid_type: string,
      createdAt: string,
      id: string,
      pkey: string,
      ruleid: string,
      time: number,
      type: string,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AlertHistoryByTypeQueryVariables = {
  filter?: ModelalertHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
  type?: string | null,
};

export type AlertHistoryByTypeQuery = {
  alertHistoryByType:  {
    __typename: "ModelalertHistoryConnection",
    items:  Array< {
      __typename: "alertHistory",
      comid: string,
      comid_type: string,
      createdAt: string,
      id: string,
      pkey: string,
      ruleid: string,
      time: number,
      type: string,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AlertRuleByComidQueryVariables = {
  comid?: string | null,
  filter?: ModelalertRuleFilterInput | null,
  is_active?: ModelStringKeyConditionInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type AlertRuleByComidQuery = {
  alertRuleByComid:  {
    __typename: "ModelalertRuleConnection",
    items:  Array< {
      __typename: "alertRule",
      comid: string,
      createdAt: string,
      id: string,
      interval_in_seconds: number,
      is_active: string,
      name: string,
      notifications: string,
      rule: string,
      time: number,
      type: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type DeviceAlgorithmByCategoryAndNameQueryVariables = {
  category?: string | null,
  filter?: ModeldeviceAlgorithmFilterInput | null,
  limit?: number | null,
  name?: ModelStringKeyConditionInput | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type DeviceAlgorithmByCategoryAndNameQuery = {
  deviceAlgorithmByCategoryAndName:  {
    __typename: "ModeldeviceAlgorithmConnection",
    items:  Array< {
      __typename: "deviceAlgorithm",
      algorithm: string | null,
      category: string,
      comids: string | null,
      createdAt: string,
      id: string,
      name: string,
      time: number,
      type: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type DeviceAlgorithmByTypeAndLastUpdateTimeQueryVariables = {
  filter?: ModeldeviceAlgorithmFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
  type?: string | null,
};

export type DeviceAlgorithmByTypeAndLastUpdateTimeQuery = {
  deviceAlgorithmByTypeAndLastUpdateTime:  {
    __typename: "ModeldeviceAlgorithmConnection",
    items:  Array< {
      __typename: "deviceAlgorithm",
      algorithm: string | null,
      category: string,
      comids: string | null,
      createdAt: string,
      id: string,
      name: string,
      time: number,
      type: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type DeviceAlgorithmByTypeAndNameQueryVariables = {
  filter?: ModeldeviceAlgorithmFilterInput | null,
  limit?: number | null,
  name?: ModelStringKeyConditionInput | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  type?: string | null,
};

export type DeviceAlgorithmByTypeAndNameQuery = {
  deviceAlgorithmByTypeAndName:  {
    __typename: "ModeldeviceAlgorithmConnection",
    items:  Array< {
      __typename: "deviceAlgorithm",
      algorithm: string | null,
      category: string,
      comids: string | null,
      createdAt: string,
      id: string,
      name: string,
      time: number,
      type: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type DeviceHistoricalsByComidQueryVariables = {
  comid?: string | null,
  filter?: ModeldeviceHistoricalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type DeviceHistoricalsByComidQuery = {
  deviceHistoricalsByComid:  {
    __typename: "ModeldeviceHistoricalConnection",
    items:  Array< {
      __typename: "deviceHistorical",
      alerts: string | null,
      comid: string,
      createdAt: string,
      id: string,
      time: number,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type DevicesByComidQueryVariables = {
  comid?: string | null,
  filter?: ModeldeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type DevicesByComidQuery = {
  devicesByComid:  {
    __typename: "ModeldeviceConnection",
    items:  Array< {
      __typename: "device",
      alg_c_interval_in_seconds: number | null,
      alg_c_is_active: boolean | null,
      alg_c_last_cal_status: string | null,
      alg_c_uuid: string | null,
      alg_p_interval_in_seconds: number | null,
      alg_p_is_active: boolean | null,
      alg_p_last_cal_status: string | null,
      alg_p_last_cal_time: number | null,
      alg_p_uuid: string | null,
      category: string,
      comid: string,
      controllable: number,
      createdAt: string,
      device_name_jp: string | null,
      deviceid: string,
      flow: string | null,
      items: string,
      manufacturer: string | null,
      meta_data: string | null,
      placeid: string,
      position: string | null,
      protocols: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type DevicesByControllableQueryVariables = {
  comid?: ModelStringKeyConditionInput | null,
  controllable?: number | null,
  filter?: ModeldeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type DevicesByControllableQuery = {
  devicesByControllable:  {
    __typename: "ModeldeviceConnection",
    items:  Array< {
      __typename: "device",
      alg_c_interval_in_seconds: number | null,
      alg_c_is_active: boolean | null,
      alg_c_last_cal_status: string | null,
      alg_c_uuid: string | null,
      alg_p_interval_in_seconds: number | null,
      alg_p_is_active: boolean | null,
      alg_p_last_cal_status: string | null,
      alg_p_last_cal_time: number | null,
      alg_p_uuid: string | null,
      category: string,
      comid: string,
      controllable: number,
      createdAt: string,
      device_name_jp: string | null,
      deviceid: string,
      flow: string | null,
      items: string,
      manufacturer: string | null,
      meta_data: string | null,
      placeid: string,
      position: string | null,
      protocols: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAlertHistoryQueryVariables = {
  id: string,
};

export type GetAlertHistoryQuery = {
  getAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type GetAlertRuleQueryVariables = {
  id: string,
};

export type GetAlertRuleQuery = {
  getAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type GetDeviceQueryVariables = {
  comid: string,
  placeid: string,
};

export type GetDeviceQuery = {
  getDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type GetDeviceAlgorithmQueryVariables = {
  id: string,
};

export type GetDeviceAlgorithmQuery = {
  getDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type GetDeviceCtrlValueQueryVariables = {
  comid: string,
  time: number,
};

export type GetDeviceCtrlValueQuery = {
  getDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type GetDeviceHistoricalQueryVariables = {
  id: string,
};

export type GetDeviceHistoricalQuery = {
  getDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type GetDevicePlanValueQueryVariables = {
  comid: string,
  time: number,
};

export type GetDevicePlanValueQuery = {
  getDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type GetOperateLogQueryVariables = {
  id: string,
};

export type GetOperateLogQuery = {
  getOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type ListAlertHistorysQueryVariables = {
  filter?: ModelalertHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlertHistorysQuery = {
  listAlertHistorys:  {
    __typename: "ModelalertHistoryConnection",
    items:  Array< {
      __typename: "alertHistory",
      comid: string,
      comid_type: string,
      createdAt: string,
      id: string,
      pkey: string,
      ruleid: string,
      time: number,
      type: string,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListAlertRulesQueryVariables = {
  filter?: ModelalertRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlertRulesQuery = {
  listAlertRules:  {
    __typename: "ModelalertRuleConnection",
    items:  Array< {
      __typename: "alertRule",
      comid: string,
      createdAt: string,
      id: string,
      interval_in_seconds: number,
      is_active: string,
      name: string,
      notifications: string,
      rule: string,
      time: number,
      type: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListDeviceAlgorithmsQueryVariables = {
  filter?: ModeldeviceAlgorithmFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceAlgorithmsQuery = {
  listDeviceAlgorithms:  {
    __typename: "ModeldeviceAlgorithmConnection",
    items:  Array< {
      __typename: "deviceAlgorithm",
      algorithm: string | null,
      category: string,
      comids: string | null,
      createdAt: string,
      id: string,
      name: string,
      time: number,
      type: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListDeviceCtrlValuesQueryVariables = {
  comid?: string | null,
  filter?: ModeldeviceCtrlValueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type ListDeviceCtrlValuesQuery = {
  listDeviceCtrlValues:  {
    __typename: "ModeldeviceCtrlValueConnection",
    items:  Array< {
      __typename: "deviceCtrlValue",
      comid: string,
      createdAt: string,
      time: number,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListDeviceHistoricalsQueryVariables = {
  filter?: ModeldeviceHistoricalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceHistoricalsQuery = {
  listDeviceHistoricals:  {
    __typename: "ModeldeviceHistoricalConnection",
    items:  Array< {
      __typename: "deviceHistorical",
      alerts: string | null,
      comid: string,
      createdAt: string,
      id: string,
      time: number,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListDevicePlanValuesQueryVariables = {
  comid?: string | null,
  filter?: ModeldevicePlanValueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type ListDevicePlanValuesQuery = {
  listDevicePlanValues:  {
    __typename: "ModeldevicePlanValueConnection",
    items:  Array< {
      __typename: "devicePlanValue",
      comid: string,
      createdAt: string,
      time: number,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListDevicesQueryVariables = {
  comid?: ModelStringKeyConditionInput | null,
  filter?: ModeldeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  placeid?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDevicesQuery = {
  listDevices:  {
    __typename: "ModeldeviceConnection",
    items:  Array< {
      __typename: "device",
      alg_c_interval_in_seconds: number | null,
      alg_c_is_active: boolean | null,
      alg_c_last_cal_status: string | null,
      alg_c_uuid: string | null,
      alg_p_interval_in_seconds: number | null,
      alg_p_is_active: boolean | null,
      alg_p_last_cal_status: string | null,
      alg_p_last_cal_time: number | null,
      alg_p_uuid: string | null,
      category: string,
      comid: string,
      controllable: number,
      createdAt: string,
      device_name_jp: string | null,
      deviceid: string,
      flow: string | null,
      items: string,
      manufacturer: string | null,
      meta_data: string | null,
      placeid: string,
      position: string | null,
      protocols: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListOperateLogsQueryVariables = {
  filter?: ModeloperateLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOperateLogsQuery = {
  listOperateLogs:  {
    __typename: "ModeloperateLogConnection",
    items:  Array< {
      __typename: "operateLog",
      comid: string,
      createdAt: string,
      id: string,
      time: number,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OperateLogByComidQueryVariables = {
  comid?: string | null,
  filter?: ModeloperateLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  time?: ModelTimeKeyConditionInput | null,
};

export type OperateLogByComidQuery = {
  operateLogByComid:  {
    __typename: "ModeloperateLogConnection",
    items:  Array< {
      __typename: "operateLog",
      comid: string,
      createdAt: string,
      id: string,
      time: number,
      updatedAt: string,
      values: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateAlertHistorySubscription = {
  onCreateAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnCreateAlertRuleSubscription = {
  onCreateAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceSubscription = {
  onCreateDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceAlgorithmSubscription = {
  onCreateDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceCtrlValueSubscription = {
  onCreateDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnCreateDeviceHistoricalSubscription = {
  onCreateDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnCreateDevicePlanValueSubscription = {
  onCreateDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnCreateOperateLogSubscription = {
  onCreateOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnDeleteAlertHistorySubscription = {
  onDeleteAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnDeleteAlertRuleSubscription = {
  onDeleteAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceAlgorithmSubscription = {
  onDeleteDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceCtrlValueSubscription = {
  onDeleteDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnDeleteDeviceHistoricalSubscription = {
  onDeleteDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnDeleteDevicePlanValueSubscription = {
  onDeleteDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnDeleteOperateLogSubscription = {
  onDeleteOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnUpdateAlertHistorySubscription = {
  onUpdateAlertHistory:  {
    __typename: "alertHistory",
    comid: string,
    comid_type: string,
    createdAt: string,
    id: string,
    pkey: string,
    ruleid: string,
    time: number,
    type: string,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnUpdateAlertRuleSubscription = {
  onUpdateAlertRule:  {
    __typename: "alertRule",
    comid: string,
    createdAt: string,
    id: string,
    interval_in_seconds: number,
    is_active: string,
    name: string,
    notifications: string,
    rule: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice:  {
    __typename: "device",
    alg_c_interval_in_seconds: number | null,
    alg_c_is_active: boolean | null,
    alg_c_last_cal_status: string | null,
    alg_c_uuid: string | null,
    alg_p_interval_in_seconds: number | null,
    alg_p_is_active: boolean | null,
    alg_p_last_cal_status: string | null,
    alg_p_last_cal_time: number | null,
    alg_p_uuid: string | null,
    category: string,
    comid: string,
    controllable: number,
    createdAt: string,
    device_name_jp: string | null,
    deviceid: string,
    flow: string | null,
    items: string,
    manufacturer: string | null,
    meta_data: string | null,
    placeid: string,
    position: string | null,
    protocols: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceAlgorithmSubscription = {
  onUpdateDeviceAlgorithm:  {
    __typename: "deviceAlgorithm",
    algorithm: string | null,
    category: string,
    comids: string | null,
    createdAt: string,
    id: string,
    name: string,
    time: number,
    type: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceCtrlValueSubscription = {
  onUpdateDeviceCtrlValue:  {
    __typename: "deviceCtrlValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnUpdateDeviceHistoricalSubscription = {
  onUpdateDeviceHistorical:  {
    __typename: "deviceHistorical",
    alerts: string | null,
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnUpdateDevicePlanValueSubscription = {
  onUpdateDevicePlanValue:  {
    __typename: "devicePlanValue",
    comid: string,
    createdAt: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

export type OnUpdateOperateLogSubscription = {
  onUpdateOperateLog:  {
    __typename: "operateLog",
    comid: string,
    createdAt: string,
    id: string,
    time: number,
    updatedAt: string,
    values: string,
  } | null,
};

/**
 * Nested JSON Objectのnullを消す関数、
 * number | string | boolean | Array<any> | JSON
 * を 0 | "" | false | [] | recursion にする
 * @jsonIn 標準型のjavascript object. expect typeof JSON.parse(jsonIn) === "object"
 */
export function ClumsyNullGuardForNestedObject(objIn: object) {
  const inObject = objIn
  const outObject: any = {}

  if (!inObject) {
    return
  }
  for (const [key, value] of Object.entries(inObject)) {
    /** false, 0, -0, "", null, undefined, NaN are falsy */
    if (value) {
      outObject[key] = value
    } else {
      switch (value) {
        case '':
          outObject[key] = ''
          break
        /** 0 | boolean | null | NaN?, explode if nested JSON object field is null */
        default:
          outObject[key] = 0
      }
    }

    /** nested javascript object, since not null */
    if (typeof value === 'object') {
      ClumsyNullGuardForNestedObject(value)
    }
  }

  return outObject
}

export default {
  ClumsyNullGuardForNestedObject,
}

import { InjectionKey, reactive, set } from '@vue/composition-api'
import { GraphQLResult } from '@aws-amplify/api'
import { CreateDeviceInput, ListDevicesQuery } from '@/API'
import * as SchemaType from '@/store/amplify/extraSchemaTypes'
import deviceService from '@/store/amplify/deviceService'
import jsonUtil from '@/util/jsonUtil'
import { Logger } from '@/util/logger'

/**
 * ストア内部のデータ構造の型定義。
 *
 * the store data type
 */
export interface DeviceRecords
  extends Record<SchemaType.QLComid, { itemsObj: { [key: string]: { unitMultiplier: number; unit: string } } }> {}

/**
 * ロガー用ファイルパス
 */
const tsName = 'store/actions/deviceStore.ts'

/**
 * device store本体
 *
 * Description: the Device Store, holding all device info, comid as key.
 */
const DeviceStore = () => {
  /**
   * 実際のデータstore.
   *
   * the actual data storage
   */
  const state = reactive<DeviceRecords>({})

  async function fetchAllDevices() {
    let graphqlResponse: GraphQLResult<ListDevicesQuery> | undefined | null = null
    try {
      graphqlResponse = <GraphQLResult<ListDevicesQuery> | undefined>await deviceService.findAllDevices()
    } catch (err) {
      Logger.fatal(`${tsName}#fetchAllDevices`, 'Unexpected error has occurred', err)
      throw err
    }

    if (graphqlResponse) {
      const payload: {
        [key: string]: CreateDeviceInput
      } = {}
      const devicesArray = graphqlResponse.data?.listDevices?.items
      /**
       * responseにデータが存在する場合、そのデータを処理する。
       *
       * if response has info, parse data
       */
      if (!devicesArray || !(devicesArray.length > 0)) {
        return
      }
      for (const device of devicesArray) {
        /** guard in case a data record is corrupted */
        if (device?.comid && device.deviceid && device.placeid && device.category) {
          const payloadKey = device?.comid!
          payload[payloadKey] = {
            itemsObj: jsonUtil.ClumsyNullGuardForNestedObject(JSON.parse(device.items)) || {},
          } as any
        }
      }
      /** storeにデータを入れる */
      const comidArray = Object.keys(payload)
      for (const device of comidArray) {
        set(state, device, payload[device])
      }
    }
  }

  return {
    data: state,
    fetchAllDevices,
  }
}
export default DeviceStore
export type DeviceStoreReturnType = ReturnType<typeof DeviceStore>
export const DeviceStoreInjectionKey: InjectionKey<DeviceStoreReturnType> = Symbol('DeviceStore')

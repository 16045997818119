/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAlertHistory = /* GraphQL */ `
  subscription OnCreateAlertHistory {
    onCreateAlertHistory {
      comid
      comid_type
      createdAt
      id
      pkey
      ruleid
      time
      type
      updatedAt
      values
    }
  }
`;
export const onCreateAlertRule = /* GraphQL */ `
  subscription OnCreateAlertRule {
    onCreateAlertRule {
      comid
      createdAt
      id
      interval_in_seconds
      is_active
      name
      notifications
      rule
      time
      type
      updatedAt
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      alg_c_interval_in_seconds
      alg_c_is_active
      alg_c_last_cal_status
      alg_c_uuid
      alg_p_interval_in_seconds
      alg_p_is_active
      alg_p_last_cal_status
      alg_p_last_cal_time
      alg_p_uuid
      category
      comid
      controllable
      createdAt
      device_name_jp
      deviceid
      flow
      items
      manufacturer
      meta_data
      placeid
      position
      protocols
      updatedAt
    }
  }
`;
export const onCreateDeviceAlgorithm = /* GraphQL */ `
  subscription OnCreateDeviceAlgorithm {
    onCreateDeviceAlgorithm {
      algorithm
      category
      comids
      createdAt
      id
      name
      time
      type
      updatedAt
    }
  }
`;
export const onCreateDeviceCtrlValue = /* GraphQL */ `
  subscription OnCreateDeviceCtrlValue {
    onCreateDeviceCtrlValue {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const onCreateDeviceHistorical = /* GraphQL */ `
  subscription OnCreateDeviceHistorical {
    onCreateDeviceHistorical {
      alerts
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const onCreateDevicePlanValue = /* GraphQL */ `
  subscription OnCreateDevicePlanValue {
    onCreateDevicePlanValue {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const onCreateOperateLog = /* GraphQL */ `
  subscription OnCreateOperateLog {
    onCreateOperateLog {
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const onDeleteAlertHistory = /* GraphQL */ `
  subscription OnDeleteAlertHistory {
    onDeleteAlertHistory {
      comid
      comid_type
      createdAt
      id
      pkey
      ruleid
      time
      type
      updatedAt
      values
    }
  }
`;
export const onDeleteAlertRule = /* GraphQL */ `
  subscription OnDeleteAlertRule {
    onDeleteAlertRule {
      comid
      createdAt
      id
      interval_in_seconds
      is_active
      name
      notifications
      rule
      time
      type
      updatedAt
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      alg_c_interval_in_seconds
      alg_c_is_active
      alg_c_last_cal_status
      alg_c_uuid
      alg_p_interval_in_seconds
      alg_p_is_active
      alg_p_last_cal_status
      alg_p_last_cal_time
      alg_p_uuid
      category
      comid
      controllable
      createdAt
      device_name_jp
      deviceid
      flow
      items
      manufacturer
      meta_data
      placeid
      position
      protocols
      updatedAt
    }
  }
`;
export const onDeleteDeviceAlgorithm = /* GraphQL */ `
  subscription OnDeleteDeviceAlgorithm {
    onDeleteDeviceAlgorithm {
      algorithm
      category
      comids
      createdAt
      id
      name
      time
      type
      updatedAt
    }
  }
`;
export const onDeleteDeviceCtrlValue = /* GraphQL */ `
  subscription OnDeleteDeviceCtrlValue {
    onDeleteDeviceCtrlValue {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const onDeleteDeviceHistorical = /* GraphQL */ `
  subscription OnDeleteDeviceHistorical {
    onDeleteDeviceHistorical {
      alerts
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const onDeleteDevicePlanValue = /* GraphQL */ `
  subscription OnDeleteDevicePlanValue {
    onDeleteDevicePlanValue {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const onDeleteOperateLog = /* GraphQL */ `
  subscription OnDeleteOperateLog {
    onDeleteOperateLog {
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const onUpdateAlertHistory = /* GraphQL */ `
  subscription OnUpdateAlertHistory {
    onUpdateAlertHistory {
      comid
      comid_type
      createdAt
      id
      pkey
      ruleid
      time
      type
      updatedAt
      values
    }
  }
`;
export const onUpdateAlertRule = /* GraphQL */ `
  subscription OnUpdateAlertRule {
    onUpdateAlertRule {
      comid
      createdAt
      id
      interval_in_seconds
      is_active
      name
      notifications
      rule
      time
      type
      updatedAt
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      alg_c_interval_in_seconds
      alg_c_is_active
      alg_c_last_cal_status
      alg_c_uuid
      alg_p_interval_in_seconds
      alg_p_is_active
      alg_p_last_cal_status
      alg_p_last_cal_time
      alg_p_uuid
      category
      comid
      controllable
      createdAt
      device_name_jp
      deviceid
      flow
      items
      manufacturer
      meta_data
      placeid
      position
      protocols
      updatedAt
    }
  }
`;
export const onUpdateDeviceAlgorithm = /* GraphQL */ `
  subscription OnUpdateDeviceAlgorithm {
    onUpdateDeviceAlgorithm {
      algorithm
      category
      comids
      createdAt
      id
      name
      time
      type
      updatedAt
    }
  }
`;
export const onUpdateDeviceCtrlValue = /* GraphQL */ `
  subscription OnUpdateDeviceCtrlValue {
    onUpdateDeviceCtrlValue {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const onUpdateDeviceHistorical = /* GraphQL */ `
  subscription OnUpdateDeviceHistorical {
    onUpdateDeviceHistorical {
      alerts
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;
export const onUpdateDevicePlanValue = /* GraphQL */ `
  subscription OnUpdateDevicePlanValue {
    onUpdateDevicePlanValue {
      comid
      createdAt
      time
      updatedAt
      values
    }
  }
`;
export const onUpdateOperateLog = /* GraphQL */ `
  subscription OnUpdateOperateLog {
    onUpdateOperateLog {
      comid
      createdAt
      id
      time
      updatedAt
      values
    }
  }
`;

export default class InstantValAnimationStore {
  public static PIPE_LINE = {
    UP: 'data-up-pipeline',
    DOWN: 'data-down-pipeline',
  }

  private _name = ''
  private _pipeline = ''
  private _animate = false
  private _logics: Function[] = []

  constructor(name: string, pipeline: string, logics: Function[]) {
    this._name = name
    this._pipeline = pipeline
    this._logics = logics
  }

  updateAnimation(value: { [key: string]: string }) {
    if (this._logics.length === 0) {
      this._animate = false
      return
    }

    this._animate = this._logics.every((logic: Function) => logic(value))
  }

  get name() {
    return this._name
  }

  get pipeline() {
    return this._pipeline
  }

  get animate() {
    return this._animate
  }
}

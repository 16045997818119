import { InjectionKey, reactive, set } from '@vue/composition-api'
import { ZenObservable } from 'zen-observable-ts'
import DevicePlanValueService, {
  findDevicePlanValueByComIDAndTimeUserInput,
} from '@/store/amplify/devicePlanValueService'
import * as SchemaType from '@/store/amplify/extraSchemaTypes'
import { OnCreateDevicePlanValueSubscription, ModelSortDirection } from '@/API'
import jsonUtil from '@/util/jsonUtil'
import { Logger } from '@/util/logger'

export type history = {
  /** javascript dateTime */
  dateTime: Date
  valuesObj: { [key: string]: SchemaType.QLJsonContent }
}

export interface devicePlanValueRecords extends Record<SchemaType.QLComid, history[]> {}

/**
 * ストアに追加する計画値情報のインタフェース
 */
export interface addARecordPayload {
  comid: SchemaType.QLComid
  dateTime: SchemaType.QLDateTime
  values: Record<string, SchemaType.QLJsonContent>
}

/**
 * ロガー用ファイルパス
 */
const tsName = 'store/actions/planValueStore.ts'

/**
 * 計画値ストア
 */
const PlanValueStore = () => {
  /** ストア初期化 */
  const state = reactive<devicePlanValueRecords>({})
  set(state, 'init', [
    {
      dateTime: new Date(),
      valuesObj: {},
    },
  ])

  /** サブスクライブ */
  let subscriptionNewPlanValueRecords: ZenObservable.Subscription | null = null

  /** 計画値テーブルの新規追加することをサブスクライブする, AppSyncからプッシュされたデータをストアに追加 */
  function subscribeToNewPlanValueRecords(devices: Readonly<{ comid: string; items: string[] }[]>) {
    const putNewDataToStore = function (data: OnCreateDevicePlanValueSubscription) {
      try {
        const newRecord = {
          comid: data.onCreateDevicePlanValue!.comid,
          dateTime: data.onCreateDevicePlanValue!.time,
          values: JSON.parse(data.onCreateDevicePlanValue!.values),
        }
        const comid = data.onCreateDevicePlanValue!.comid
        if (devices.some((device) => device.comid === comid)) {
          const items = devices.find((item) => item.comid === comid)?.items as string[]
          addARecord(newRecord, items)
        }
      } catch (err) {
        Logger.fatal(`${tsName}#putNewDataToStore`, 'add record into store fatal', err)
        throw err
      }
    }

    /** サブスクライブが存在しない、もしくはクローズした場合、再びサブスクライブする */
    const shouldSubscribe: boolean = !subscriptionNewPlanValueRecords || subscriptionNewPlanValueRecords?.closed
    if (!shouldSubscribe) {
      return
    }
    subscriptionNewPlanValueRecords = DevicePlanValueService.subscribeToDevicePlanValueOnCreate(putNewDataToStore)
  }

  /** サブスクライブをクローズする */
  function unsubscribeToNewPlanValueRecords() {
    if (!subscriptionNewPlanValueRecords || subscriptionNewPlanValueRecords.closed) {
      return
    }
    subscriptionNewPlanValueRecords.unsubscribe()
  }

  /** 計画値を検索する、ストアに追加 */
  async function queryPlanValueRecords(devices: Readonly<{ comid: string; items: string[] }[]>, time: number[]) {
    const userInput: findDevicePlanValueByComIDAndTimeUserInput = {
      comid: devices[0].comid,
      time,
      sortDirection: ModelSortDirection.DESC,
    }
    const {
      data: {
        listDevicePlanValues: { items },
      },
    } = (await DevicePlanValueService.findDevicePlanValuesByComIDAndTime(userInput)) as any

    if (items.length === 0) {
      return
    }

    const newRecord = {
      comid: items[0].comid,
      dateTime: items[0].time,
      values: JSON.parse(items[0].values),
    }
    addARecord(newRecord, devices[0].items)
  }

  /**
   * 計画値情報一件をストアに追加する
   */
  function addARecord(newRecord: addARecordPayload, items: string[]) {
    const comid = newRecord.comid

    /**
     * 該当comidがストアのキーに存在しない場合それを追加し初期化する
     *
     * initialize the key value pair in the store, if not exist
     */
    if (!state[comid]) {
      set(state, comid, [])
    }

    /** res.data.....items は JSONオブジェクトだから */
    const jsonObj = jsonUtil.ClumsyNullGuardForNestedObject(newRecord.values)
    /** itemsを絞る */
    const valuesObj: { [key: string]: SchemaType.QLJsonContent } = _filterItems(jsonObj, items)
    state[comid]!.push({
      dateTime: new Date(newRecord.dateTime),
      valuesObj,
    })
  }

  /**
   * objectのitemsを絞る
   */
  function _filterItems(jsObjFromJSON: { [key: string]: SchemaType.QLJsonContent }, items: string[]) {
    const valuesObj: { [key: string]: SchemaType.QLJsonContent } = {}
    items.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(jsObjFromJSON, item)) {
        valuesObj[item] = jsObjFromJSON[item]
      }
    })
    return valuesObj
  }

  return {
    data: state,
    subscribeToNewPlanValueRecords,
    unsubscribeToNewPlanValueRecords,
    queryPlanValueRecords,
  }
}
export default PlanValueStore
export type PlanValueStoreReturnType = ReturnType<typeof PlanValueStore>
export const PlanStoreInjectionKey: InjectionKey<PlanValueStoreReturnType> = Symbol('PlanValueStore')


















































import { defineComponent, watch, ref, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    isOffline: {
      type: Boolean,
      default: false,
    },
    isStopped: {
      type: Boolean,
      default: false,
    },
    latestTime: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    // 通知トースト開閉フラグ
    const isShow = ref(false)

    // ピンボタン開閉フラグ
    const isPinned = ref(false)

    // カウントダウン用パーセンテージ
    const percentage = ref(100)

    // 通知トーストを表示する
    watch(
      () => [props.isOffline, props.isStopped],
      () => {
        isShow.value = props.isOffline || props.isStopped
        if (isShow.value) {
          if (!timer) {
            _startTimer()
          }
        } else {
          _endTimer()
        }
      },
      { deep: true, immediate: true }
    )

    // タイマー
    let timer: NodeJS.Timeout | null

    // タイマーを開始する
    function _startTimer() {
      _endTimer()
      timer = setInterval(() => {
        percentage.value--
        if (percentage.value === 0) {
          location.reload()
        }
      }, 1000)
    }

    // タイマーを消す
    function _endTimer() {
      percentage.value = 100
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    }

    // 最新データ時刻
    const latestTimeStr = computed(() => {
      if (props.latestTime === 0) {
        return 'pending...'
      } else {
        const date = new Date(props.latestTime)
        return date.toLocaleString() + '.' + date.getMilliseconds().toString().padStart(3, '0')
      }
    })

    // インターネット接続が切れた場合に、ボタンクリックで画面をリロードする
    function handleReloadBtnClick() {
      location.reload()
    }

    // 通知トーストを閉じる
    function handleCancelBtnClick() {
      isShow.value = false
      _endTimer()
    }

    return {
      handleReloadBtnClick,
      handleCancelBtnClick,
      isShow,
      latestTimeStr,
      percentage,
      isPinned,
    }
  },
})

import { API, graphqlOperation } from 'aws-amplify'
import Observable from 'zen-observable-ts'
import * as subscriptions from '@/graphql/subscriptions'
import * as queries from '@/graphql/queries'
import * as SchemaType from '@/store/amplify/extraSchemaTypes'
import { OnCreateDeviceHistoricalSubscription, DeviceHistoricalsByComidQueryVariables, ModelSortDirection } from '@/API'

/**
 * 一回のGraghQL queryにあたって取得する記録の数を制限する値のデフォルト値。
 *
 * default numbers of records per page
 */
const DEFAULT_PAGING_LIMITS = 100

/** ***************************default-consts************************************/
/**
 * subscriberに対し、deviceHistoricalDBに新しいデータが追加された時に、appsyncによってpushされるデータの型。
 * metadataと問い合わせたデータ両方を含む。
 *
 * Description: the message pushed by appsync, containing
 * metadata+data_we_want, on new DeviceHictoricalRecord creation.
 */
export type onCreateDeviceHistoricalSubscriptionMessage = {
  /**
   * metadata、ここでは扱わないためanyに定義する
   *
   * provider(metadata): AWSAppSyncRealTimeProvider */
  provider: any
  /**
   * "value"というキーの下に、実際のデータが存在する。
   *
   *  value(automatically created level): { data(the actual new data which is pushed) } */
  value: { data: OnCreateDeviceHistoricalSubscription }
}

/**
 * subscribeでpushされたデータを実際に処理するcallback関数。
 *
 *  type of the call back function
 */
export type subscribeToDeviceHistoricalOnCreateCBFunction = (data: OnCreateDeviceHistoricalSubscription) => void

/**
 * subscription API. 実際にsubscriptionを行う関数。pushされたデータに対する処理は引数のcallback関数によって行われる。
 * 戻り値はそのsubscriptionを表すobjectで、中ではそのsubscribeをunsubscribeできる関数も含まれている。
 *
 * Description: subscription API.
 * Usage: input a function(the newest data pushed by the appsync API as its
 * only input), which will be call backed whenever a new record is received.
 *
 * Example:
 * const subscription = subscribeDeviceHistoricalValue( (incomingNewData) => void );
 * subscription.unscribe();
 *
 */
const subscribeToDeviceHistoricalOnCreate = (callbackFunction: subscribeToDeviceHistoricalOnCreateCBFunction) => {
  const subscription = (<Observable<Object>>(
    API.graphql(graphqlOperation(subscriptions.onCreateDeviceHistorical))
  )).subscribe({
    next: ({ value: { data } }: onCreateDeviceHistoricalSubscriptionMessage) => {
      callbackFunction(data)
    },
  })
  return subscription
}

/**
 * comidと時間の幅を指定してデータをDBから取ってくる方法。
 *
 * Description: get records by com_id; Since in the schema @key[id, DateTime],
 * the data fetched shall be sorted according to the DateTime
 * the nextToken is empty if no more result. it should not exist in the first query
 *
 * Example Payload:
 * query MyQuery {
 * listDeviceHistoricals(
 *   limit: 10,
 *   filter: {
 *     comid: {eq: "c011"}, unixEpochTime: {between: [1602564025480, 1602565331784]}
 *   }
 * ) {
 *   items {
 *     comid
 *     unixEpochTime
 *     value
 *   }
 *   nextToken
 * }
 *
 */
const findDeviceHistoricalsByComIDAndTime = async (userInput: findDeviceHistoricalByComIDAndTimeUserInput) => {
  const payload: DeviceHistoricalsByComidQueryVariables = {
    comid: userInput.comid,
    time: { between: userInput.time },
    sortDirection: userInput.sortDirection,
    filter: null,
    limit: userInput.limit || DEFAULT_PAGING_LIMITS,
    /** null specified by the payload interface */
    nextToken: userInput.nextToken || null,
  }

  return await API.graphql(graphqlOperation(queries.deviceHistoricalsByComid, payload))
}

/**
 * findDeviceHistoricalByComIDAndTimeのinputの型定義。
 *
 * type of the input of the method findDeviceHistoricalByComIDAndTime
 */
export type findDeviceHistoricalByComIDAndTimeUserInput = {
  comid: SchemaType.QLComid
  /**
   * unix epoch timeを二個いれる
   *
   *  unixTimePeriods: 2 long number, less than +-2^53 -1, safe to be stored as JavaScript number
   */
  // DBと合わせる為、 以下ESLintの除去を許容する
  // eslint-disable-next-line camelcase
  time: number[]

  /**
   * 一回のGraphQLに当たってどのぐらいのデータを取ってくるのかの制限するlimit
   *
   * paging limit, maxium result count per GraphQL query
   */
  limit?: number
  /**
   * limitを使用してqueryを投げた場合、次のページが始まる場所をマークするtokenです。
   *
   * the reference points to the next page, returned by previous query
   */
  nextToken?: SchemaType.QLNextToken

  sortDirection?: ModelSortDirection
}

/**
 * placeidでdbを検索する時使用するuserInputの型定義
 *
 *  the userInput for searching devices of some location
 */
export type findDevicesByLocationUserInput = {
  /**
   * placeid
   */
  placeID: SchemaType.QLPlaceid
  /**
   * 一回のGraphQLに当たってどのぐらいのデータを取ってくるのかの制限するlimit
   *
   * paging limit, maxium result count per GraphQL query
   */
  limit?: number
  /**
   * limitを使用してqueryを投げた場合、次のページが始まる場所をマークするtokenです。
   *
   * the reference points to the next page, returned by previous query
   */
  nextToken?: SchemaType.QLNextToken
}

export default {
  subscribeToDeviceHistoricalOnCreate,
  findDeviceHistoricalsByComIDAndTime,
}

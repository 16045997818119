import axios from 'axios'
import Transport from 'winston-transport'

//
// Inherit from `winston-transport` so you can take advantage
// of the base functionality and `.exceptions.handle()`.
//
export default class CloudWatchTransport extends Transport {
  log(info: any, callback: () => void) {
    const endpoint = `${process.env.APIGATEWAY_ENDPOINT}/logs`
    const data = {
      clientTimestamp: info.timestamp,
      category: info.service,
      name: info.message.name,
      customLevel: info.message.customLevel ? info.message.customLevel : info.level,
      position: info.message.position,
      msg: info.message.msg,
      error1: '',
      error2: '',
    }
    if (info.message.error) {
      data.error1 = info.message.error.message
      data.error2 = info.message.error.stack ? info.message.error.stack.split('\n') : []
    }

    axios.post(endpoint, JSON.stringify(data), {
      headers: {
        'X-API-KEY': process.env.APIGATEWAY_APIKEY,
      },
    })

    if (callback) {
      callback()
    }
  }
}

import { QueryCommand, DynamoDBClient, QueryOutput } from '@aws-sdk/client-dynamodb'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { Auth } from '@aws-amplify/auth'
import { DYNAMODB_TABLE } from '@/components/displayui/DISPLAY-UI-CONST'
import { unmarshall } from '@aws-sdk/util-dynamodb'
import awsconfig from '../../aws-exports'

const service = {
  /**
   * ComidとTimeで検索する
   */
  async queryByComidAndTime(
    ddbClient: DynamoDBClient,
    device: { comid: string; items: string[]; divide: boolean; latestOnly: boolean },
    time: number[]
  ) {
    // 指定する項目のみを検索する
    let itemStr = ''
    // スペースを含む場合、式の属性名を使用して式のその属性名を置き換える
    const aliasMap: { [key: string]: string } = {}
    device.items
      .filter((e) => e !== 'timestamp')
      .forEach((e) => {
        const trimStr = e.replace(/\s+/g, '')
        itemStr = itemStr + ', #values.#' + trimStr
        aliasMap['#' + trimStr] = e
      })

    const params = {
      TableName: DYNAMODB_TABLE,
      ProjectionExpression: 'id, #time, comid, createdAt, #values.#timestamp' + itemStr,
      KeyConditionExpression: 'comid = :comid AND #time BETWEEN :since AND :until',
      FilterExpression: 'contains (createdAt, :keyword)',
      ExpressionAttributeNames: { ...{ '#time': 'time', '#values': 'values', '#timestamp': 'timestamp' }, ...aliasMap },
      ExpressionAttributeValues: {
        ':comid': { S: device.comid },
        ':since': { N: time[0].toString() },
        ':until': { N: time[1].toString() },
        // データを間引く
        ':keyword': { S: device.divide ? '0Z' : '' },
      },
      ScanIndexForward: true,
      IndexName: 'byComid',
      Limit: device.latestOnly ? 1 : undefined,
    }

    let res: QueryOutput
    const itemsMerged = []
    do {
      res = await ddbClient.send(new QueryCommand(params))
      const { Items, LastEvaluatedKey } = res
      params.ExpressionAttributeValues[':since'] = LastEvaluatedKey?.time as { N: string }
      if (Items) {
        itemsMerged.push(...Items)
      }
    } while (res.LastEvaluatedKey && !device.latestOnly)

    return itemsMerged.map((e) => unmarshall(e))
  },

  /**
   * AWS.CognitoIdentityCredentialsを組む
   */
  async generateAuth() {
    const loginMapKey = `cognito-idp.${awsconfig.aws_cognito_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`
    const loginMapUserIdToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    return new DynamoDBClient({
      region: awsconfig.aws_cognito_region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: awsconfig.aws_cognito_region }),
        identityPoolId: awsconfig.aws_cognito_identity_pool_id,
        logins: { [loginMapKey]: loginMapUserIdToken },
      }),
    })
  },
}

export default service


















import { defineComponent, provide, inject, ref, computed } from '@vue/composition-api'
import DeviceStore, { DeviceStoreInjectionKey, DeviceStoreReturnType } from '@/store/actions/deviceStore'
import ActualValue, { ActualValueStoreReturnType, ActualStoreInjectionKey } from '@/store/actions/actualValueStore'
import PlanValue, { PlanValueStoreReturnType, PlanStoreInjectionKey } from '@/store/actions/planValueStore'
import dayjs from 'dayjs'
// @ts-ignore 'aws-amplify-vue'の型定義ファイルが存在しない警告回避のため
import { AmplifyEventBus } from 'aws-amplify-vue'
import { Auth } from '@aws-amplify/auth'
import { Logger } from '@/util/logger'
import { useContext } from 'nuxt-composition-api'
import {
  DISPLAYUI_COMIDS_OF_ACTUAL_VALUE,
  DISPLAYUI_COMIDS_OF_PLAN_VALUE,
} from '@/components/displayui/DISPLAY-UI-CONST'
import CICD_BUILD_TIME from '@/build-date'
import toast from '@/components/displayui/molecules/toast.vue'

export default defineComponent({
  components: {
    toast,
  },
  setup(props) {
    if (CICD_BUILD_TIME) {
      // eslint-disable-next-line no-console
      console.log('BUILD_TIME(local): ' + new Date(CICD_BUILD_TIME * 1000).toLocaleString())
    }
    const vueName = 'layouts/default.vue'
    Logger.initialize('display')
    provide(DeviceStoreInjectionKey, DeviceStore())
    provide(ActualStoreInjectionKey, ActualValue())
    provide(PlanStoreInjectionKey, PlanValue())

    /** ストアを注入する */
    const deviceStore = inject<DeviceStoreReturnType>(DeviceStoreInjectionKey)
    const historicalStore = inject<ActualValueStoreReturnType>(ActualStoreInjectionKey)
    const planValueStore = inject<PlanValueStoreReturnType>(PlanStoreInjectionKey)

    /** 検索条件 */
    const startEndOfToday = [dayjs().startOf('date').toDate().getTime(), dayjs().endOf('date').toDate().getTime()]
    const startEndOfYesterday = [
      dayjs().subtract(1, 'd').startOf('date').toDate().getTime(),
      dayjs().endOf('date').toDate().getTime(),
    ]

    /** ストア初期化完了フラグ */
    const storesAsyncInitComplete = ref(false)

    /** データ更新中断フラグ */
    const isStopped = ref(false)

    /** 最新データの時刻 */
    const latestTime = computed(() => {
      return historicalStore?.latestTimestamp.value || 0
    })

    /** タイマーを開始する */
    function _startTimer() {
      const interval = 10 * 1000
      setInterval(() => {
        isStopped.value = new Date().getTime() - latestTime.value > interval
      }, interval)
    }

    /** Amplifyサインイン状態 */
    const signedIn = ref(false)

    /** Nuxtjs error handler */
    const { error } = useContext()

    // Amplify認証ステータスが変わった時に呼び出されるイベントを登録
    AmplifyEventBus.$on('authState', (info: string) => {
      // signedInならtrue, それ以外はfalse
      signedIn.value = info === 'signedIn'
      if (!storesAsyncInitComplete.value) {
        initAllStore()
      }
    })

    /**
     * README, 重要:非同期処理でストアを初期化する。
     * 処理の流れは: 一部の画面を生成する->ストア初期化が行われ->then(flag変更)->ストアを使う子要素を生成する
     * 子要素に使用されるデータは全てreactiveではないがため、それらをloadより先に、ストアを初期化する必要があるとのことです。
     */
    function initAllStore() {
      Logger.start(`${vueName}#initAllStore`)

      Auth.currentSession()
        .then((cognitoSession) => {
          if (cognitoSession) {
            /** ["readonly", "higashimurayama"] */
            const userGroupList = cognitoSession.getAccessToken().payload['cognito:groups'] as string[]
            /** ユーザーはどのグループも属されない */
            if (!userGroupList) {
              throw new Error('unauthorized')
            }
            /** ユーザーはその他のグループに属する */
            if (
              !(
                userGroupList.includes('admin') ||
                (userGroupList.length === 1 && userGroupList.includes('readonly')) ||
                (userGroupList.includes('higashimurayama') && userGroupList.includes('readonly'))
              )
            ) {
              throw new Error('unauthorized')
            }
          }
        })
        .then(() =>
          Promise.all([
            /** deviceInfoを取得する */
            deviceStore!.fetchAllDevices(),
            /** 実績値データを一括取得する */
            historicalStore!.queryHistoricalRecords(DISPLAYUI_COMIDS_OF_ACTUAL_VALUE, startEndOfToday),
            /** 実績値テーブルの変更をサブスクライブする */
            historicalStore!.subscribeToNewHistoricalRecords(DISPLAYUI_COMIDS_OF_ACTUAL_VALUE),
            /** 計画値データを一括取得する */
            planValueStore!.queryPlanValueRecords(DISPLAYUI_COMIDS_OF_PLAN_VALUE, startEndOfYesterday),
            /** 計画値テーブルの変更をサブスクライブする */
            planValueStore!.subscribeToNewPlanValueRecords(DISPLAYUI_COMIDS_OF_PLAN_VALUE),
          ])
        )
        .then(() => {
          storesAsyncInitComplete.value = true
          _startTimer()
          Logger.end(`${vueName}#initAllStore`)
        })
        .catch((err) => {
          if (err.message === 'unauthorized') {
            error({ statusCode: 401, message: 'unauthorized' })
          } else {
            error({ statusCode: 500, message: 'system error' })
          }
          Logger.fatal(`${vueName}#initAllStore`, 'Error when initializing store', err)
          storesAsyncInitComplete.value = true
        })
    }

    // Amplify認証情報を取得する
    Auth.currentAuthenticatedUser()
      .then((value) => {
        if (value) {
          signedIn.value = true
          initAllStore()
        }
      })
      .catch((err) => {
        Logger.fatal(`${vueName}#Auth.currentAuthenticatedUser`, 'Fail to get from Amplify', err)
        throw err
      })

    return {
      props,
      storesAsyncInitComplete,
      signedIn,
      isStopped,
      latestTime,
    }
  },
})

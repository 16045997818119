//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api'
import { Logger } from '@/util/logger'
import { Auth } from '@aws-amplify/auth'
export default defineComponent({
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const vueName = 'layouts/error.vue'
    Logger.error(`${vueName}#setup`, 'Entering error page')
    function logout() {
      Auth.signOut().then(() => location.reload())
    }
    return {
      pageNotFound: '404 Not Found',
      unAuthorized: '401 このページにアクセスする権限はありません',
      otherError: '500 アプリ初期化システムエラー',
      logout,
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
})

import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import { ListDevicesQueryVariables, DevicesByComidQueryVariables } from '@/API'

/**
 * 全件取得用のメソッド
 */
const findAllDevices = async () => {
  const payload: ListDevicesQueryVariables = {
    limit: 100,
    nextToken: null,
  }
  return await API.graphql(graphqlOperation(queries.listDevices, payload))
}

/**
 * comidで取得用のメソッド
 */
const findDeviceByComid = async (comid: string) => {
  const payload: DevicesByComidQueryVariables = {
    comid,
  }
  return await API.graphql(graphqlOperation(queries.devicesByComid, payload))
}

export default {
  findAllDevices,
  findDeviceByComid,
}

import { InjectionKey, reactive, set, ref } from '@vue/composition-api'
import { ZenObservable } from 'zen-observable-ts'
import { OnCreateDeviceHistoricalSubscription } from '@/API'
import * as SchemaType from '@/store/amplify/extraSchemaTypes'
import DeviceHistoricalService from '@/store/amplify/deviceHistoricalService'
import DynamoDBService from '@/store/dynamoDB/dynamoDBService'
import jsonUtil from '@/util/jsonUtil'
import { Logger } from '@/util/logger'

/** DynamoDB記録のタイプ */
type DynamoDBRecord = {
  __typename: 'deviceHistorical'
  alerts?: string | null | undefined
  id: string
  comid: string
  time: number
  values: object
  createdAt: string
  updatedAt: string
}

export type history = {
  /** javascript dateTime */
  dateTime: Date
  valuesObj: { [key: string]: SchemaType.QLJsonContent }
}

export interface deviceHistoricalRecords extends Record<SchemaType.QLComid, history[]> {}

/**
 * レコードを新規追加する時に使用するPayload.
 * 型定義はDBに近い方が処理する時に便利になる、そのため、Schemaの型を引用する。
 *
 * Payload on adding a record.
 * Mirroring the type of schema.
 * (this payload to store type should be similar to DB data type)
 */
export interface addARecordPayload {
  comid: SchemaType.QLComid
  dateTime: SchemaType.QLDateTime
  /**
   * javascript object, { power: "20", soc: 35, xxx: "35" } データの型はDB内の実項目に準ずる
   * そのため、画面側でそれを扱う時かストアに入れる時で型変換が必要とする
   */
  values: Record<string, SchemaType.QLJsonContent>
}

/**
 * ロガー用ファイルパス
 */
const tsName = 'store/actions/actualValueStore.ts'

/**
 * 瞬時値(historical value)のstore
 *
 * the historical value store
 */
const ActualValueStore = () => {
  /**
   * 実際のデータstore.
   *
   * the actual data storage
   */
  const state = reactive<deviceHistoricalRecords>({})
  set(state, 'init', [
    {
      dateTime: new Date(),
      valuesObj: {},
    },
  ])

  /**
   * 最後のデータ入った時刻(unixtime)
   */
  const latestTimestamp = ref(0)

  /** 瞬間値表示用 */
  const stateOfInstantval = reactive<deviceHistoricalRecords>({})

  /**
   * ストアがsubscriptionをしてるかどうかをマークするflag役のobject、unsubscribeする方法を持っている。
   *
   * Description: a *store subscription state* on a DB table (onCreate new record).
   * Usage: subscriptionToNewHistoricalRecords.unsubscribe() to unsubscribe.
   */
  let subscriptionNewHistoricalRecords: ZenObservable.Subscription | null = null

  /**
   * ストアをdbにsubscribeさせるメソッド。
   *
   * Description: this store subscribe to the db change, use the appsync method.
   * Also change a *store subscription state*, which holds the corresponding
   * method to unsubscribe.
   */
  function subscribeToNewHistoricalRecords(devices: Readonly<{ comid: string; items: string[] }[]>) {
    /**
     * appsync subscribeによってpushされたデータは既存のデータより新しいと仮定できる。
     * そのため、そのデータをstoreの最後にpushすればいい。
     *
     * we may assume the incoming data is always the newest,
     * so we might simply push the new data to the end of the store.
     */
    const putNewDataToStore = function (data: OnCreateDeviceHistoricalSubscription) {
      /**
       * ガード。appsyncによってpushされた新しいデータは全フィールドがあるはずだが、万が一の不備や仕様変更の場合に備えて、
       * 空のデータをガードする。
       *
       * data pushed by the appsync should cotains all the fields,
       *  guarding in case something bad happens.
       */
      try {
        const newRecord = {
          comid: data.onCreateDeviceHistorical!.comid,
          dateTime: data.onCreateDeviceHistorical!.time,
          values: JSON.parse(data.onCreateDeviceHistorical!.values),
        }
        const comid = data.onCreateDeviceHistorical!.comid
        if (devices.some((device) => device.comid === comid)) {
          const items = devices.find((item) => item.comid === comid)?.items as string[]
          addARecord(newRecord, items)
        }
        latestTimestamp.value = new Date().getTime()
      } catch (err) {
        Logger.fatal(`${tsName}#putNewDataToStore`, 'add record into store fatal', err)
        throw err
      }
    }

    /**
     * subscription既存ではない場合、又は以前のsubscriptionはすでに終了した場合(unsubscribeしたなど)
     *
     * if subscription does not exist, or previous subscription is closed,
     * establish a new subscription.
     */
    const shouldSubscribe: boolean = !subscriptionNewHistoricalRecords || subscriptionNewHistoricalRecords?.closed
    if (!shouldSubscribe) {
      return
    }
    subscriptionNewHistoricalRecords = DeviceHistoricalService.subscribeToDeviceHistoricalOnCreate(putNewDataToStore)
  }

  /**
   * subscribeをキャンセルする。
   *
   * Description: unsubscribe, stop receiving new pushed data from the dynamoDB
   */
  function unsubscribeToNewHistoricalRecords() {
    if (!subscriptionNewHistoricalRecords || subscriptionNewHistoricalRecords.closed) {
      return
    }
    subscriptionNewHistoricalRecords.unsubscribe()
  }

  /**
   * 歴史値をDBからとってきてstoreに入れるメソッド。
   *
   * Description: query historical records from the dynamoDB
   * @param userInput parameter for quering HistoricalRecords
   */
  async function queryHistoricalRecords(
    devices: Readonly<{ comid: string; items: string[]; divide: boolean; latestOnly: boolean }[]>,
    time: number[]
  ) {
    const ddbClient = await DynamoDBService.generateAuth()
    const promiseList: Promise<{ [key: string]: any }[]>[] = []
    for (let i = 0; i < devices.length; i++) {
      promiseList.push(DynamoDBService.queryByComidAndTime(ddbClient, devices[i], time))
    }
    const gqlResponses = (await Promise.allSettled(promiseList)) as any

    for (const gqlResponse of gqlResponses) {
      if (gqlResponse && gqlResponse.status === 'fulfilled' && gqlResponse.value) {
        addRecords(gqlResponse.value)
      } else {
        Logger.fatal(
          `${tsName}#queryHistoricalRecords`,
          'in one of the gql queries unexpected error has occurred',
          gqlResponse.reason
        )
      }
    }

    // 時間順でソートする
    Object.keys(state).forEach((key) => {
      state[key].sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime())
    })

    /**
     * あるcomidの複数の記録をstoreに一括追加するメソッド
     *
     * append records of certain comid to the store
     * @param comid comid of device
     * @param res records of certain comId to be appended
     */
    function addRecords(records: DynamoDBRecord[]) {
      if (!records) {
        return
      }

      records.forEach((record) => {
        if (!record) {
          return
        }

        /** 該当するcomidが存在しない場合、comidをキーに生成する */
        if (!state[record.comid]) {
          set(state, record.comid, [])
        }

        /** res.data.....items は JSONオブジェクトだから */
        const jsObjFromJSON = jsonUtil.ClumsyNullGuardForNestedObject(record.values)
        const items = devices.find((item) => item.comid === record.comid)?.items as string[]
        /** itemsを絞る */
        const valuesObj: { [key: string]: SchemaType.QLJsonContent } = _filterItems(jsObjFromJSON, items)
        /** comidの該当するストアに追加する */
        state[record.comid].push({
          dateTime: new Date(record.time),
          valuesObj,
        })
      })
    }

    /** 瞬間値表示用 */
    for (const comid in state) {
      if (state[comid] && state[comid].length > 0) {
        set(stateOfInstantval, comid, [state[comid][state[comid].length - 1]])
      }
    }
  }

  /**
   * あるcomidの一行の記録をstoreに追加する
   *
   * append incoming new data to the store
   * @param newRecord incoming new data (possible from appsync) to be appended
   */
  function addARecord(newRecord: addARecordPayload, items: string[]) {
    const comid = newRecord.comid
    /**
     * 該当comidがストアのキーに存在しない場合それを追加し初期化する
     *
     * initialize the key value pair in the store, if not exist
     */
    if (!state[comid]) {
      set(state, comid, [])
    }
    if (!stateOfInstantval[comid]) {
      set(stateOfInstantval, comid, [])
    }

    let lastDateTime = 0
    const lastIndex = state[comid].length - 1
    if (lastIndex >= 0) {
      lastDateTime = state[comid][lastIndex].dateTime.getTime()
    }
    const newDateTime = newRecord.dateTime

    /** res.data.....items は JSONオブジェクトだから */
    const jsonObj = jsonUtil.ClumsyNullGuardForNestedObject(newRecord.values)
    /** itemsを絞る */
    const valuesObj: { [key: string]: SchemaType.QLJsonContent } = _filterItems(jsonObj, items)
    // 初期データ又は新規データのtimestamp間隔が30秒以上の場合、storeに入れる
    if (lastDateTime === 0 || (newDateTime - lastDateTime) / 1000 >= 30) {
      state[comid]!.push({
        dateTime: new Date(newRecord.dateTime),
        valuesObj,
      })
    }
    if (stateOfInstantval[comid].length === 0) {
      stateOfInstantval[comid]!.push({
        dateTime: new Date(newRecord.dateTime),
        valuesObj,
      })
    }
    if (stateOfInstantval[comid].length === 1) {
      stateOfInstantval[comid]!.splice(0, 1, {
        dateTime: new Date(newRecord.dateTime),
        valuesObj,
      })
    }
  }

  /**
   * objectのitemsを絞る
   */
  function _filterItems(jsObjFromJSON: { [key: string]: SchemaType.QLJsonContent }, items: string[]) {
    const valuesObj: { [key: string]: SchemaType.QLJsonContent } = {}
    items.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(jsObjFromJSON, item)) {
        valuesObj[item] = jsObjFromJSON[item]
      }
    })
    return valuesObj
  }

  return {
    data: state,
    latestTimestamp,
    dataOfInstantval: stateOfInstantval,
    subscribeToNewHistoricalRecords,
    unsubscribeToNewHistoricalRecords,
    queryHistoricalRecords,
  }
}
export default ActualValueStore
export type ActualValueStoreReturnType = ReturnType<typeof ActualValueStore>
export const ActualStoreInjectionKey: InjectionKey<ActualValueStoreReturnType> = Symbol('ActualValueStore')

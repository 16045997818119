import { DeviceStoreReturnType } from '@/store/actions/deviceStore'
import { ActualValueStoreReturnType } from '@/store/actions/actualValueStore'
import { serieData } from '@/store/actions/displayUIChartDataStore'
import { computed, ComputedRef, InjectionKey, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { Logger } from '@/util/logger'
import { DISPLAYUI_ITEMS } from '@/components/displayui/DISPLAY-UI-CONST'

const tsName = 'components/displayUiChartSeriesStore/displayUiChartSeriesStore.ts'

const uiSeriesStore = (historicalStore: ActualValueStoreReturnType, deviceStore: DeviceStoreReturnType) => {
  Logger.start(`${tsName}#uiSeriesStore`)

  const calculatePowerConsumption = Helper.calculatePowerConsumption

  /** chart用serieDataを生成するための関数, */
  function mapper(bld: string, itemIndex: { x: number; y: number }) {
    const element = DISPLAYUI_ITEMS[bld][itemIndex.x].elements[itemIndex.y]
    const comid = element.comid
    const data = historicalStore.data[comid]
    const item = element.item
    const time = element.time
    if (!data) {
      return null
    }
    const rtn: { x: number; y: number | null }[] = []
    const todayBegin = dayjs().startOf('date').toDate().getTime()
    /** 単位乗数取得 */
    const deviceInfo = deviceStore?.data[comid] as any
    const unitMultiplier = deviceInfo.itemsObj[item]?.unitMultiplier || 1
    /** 電力単位取得 */
    const unit = deviceInfo.itemsObj[item]?.unit
    for (const e of data) {
      /** yVal */
      let yParse = parseFloat(e.valuesObj[item] as string)
      /** 単位乗数変換 */
      yParse = yParse * unitMultiplier
      /** 電力単位変換 W => kW */
      yParse = unit === 'W' ? yParse / 1000 : yParse
      if (!yParse && yParse !== 0) {
        continue
      }

      /** xVal */
      /** データ内部のtimestampを使用する */
      const dateTime = time
        ? dayjs(e.valuesObj[time] as string)
            .toDate()
            .getTime()
        : e.dateTime.getTime()
      if (!dateTime) {
        continue
      }
      /** 24hグラフは当日のデータしか表示しない仕様のため、該当データは「今日」より昔の場合、処理しない。 */
      if (dateTime < todayBegin) {
        continue
      }

      rtn.push({
        x: dateTime,
        y: yParse,
      })
    }
    return rtn
  }

  /** 研究所全体 受電 */
  const allBldPower = computed<serieData | null>(() => mapper('allBld', { x: 1, y: 0 }))
  /** 研究所全体 太陽光発電1 */
  const allBldPv1 = computed<serieData | null>(() => mapper('allBld', { x: 0, y: 0 }))
  /** 研究所全体 太陽光発電2 */
  const allBldPv2 = computed<serieData | null>(() => mapper('allBld', { x: 0, y: 1 }))
  /** 研究所全体 蓄電池 */
  const allBldBt = computed<serieData | null>(() => mapper('allBld', { x: 3, y: 0 }))
  /** 研究所全体 水素製造 */
  const allBldWe = computed<serieData | null>(() => mapper('allBld', { x: 2, y: 0 }))
  /** 研究所全体 使用電力: 受電 + 太陽光発電 + 蓄電池 - 水素製造 */
  const allBldPowerConsumption = computed<serieData | null>(() => {
    return calculatePowerConsumption(allBldPower, allBldPv1, allBldPv2, allBldBt, allBldWe)
  })

  /** 事務棟 受電 */
  const jimuBldPower = computed<serieData | null>(() =>
    Helper.mergeManySeries([
      mapper('jimuBld', { x: 1, y: 0 }),
      mapper('jimuBld', { x: 1, y: 1 }),
      mapper('jimuBld', { x: 1, y: 2 }),
    ])
  )
  /** 事務棟 太陽光発電 */
  const jimuBldPv1 = computed<serieData | null>(() => mapper('jimuBld', { x: 0, y: 0 }))
  /** 事務棟 使用電力: 受電 + 太陽光発電 */
  const jimuBldPowerConsumption = computed<serieData | null>(() => {
    return calculatePowerConsumption(jimuBldPower, jimuBldPv1, ref(null), ref(null), ref(null))
  })

  /** fc棟 受電 */
  const fcBldPower = computed<serieData | null>(() => mapper('fcBld', { x: 1, y: 0 }))
  /** fc棟 太陽光発電 */
  const fcBldPv1 = computed<serieData | null>(() => mapper('fcBld', { x: 0, y: 0 }))
  /** fc棟 蓄電池 */
  const fcBldBt = computed<serieData | null>(() => mapper('fcBld', { x: 3, y: 0 }))
  /** fc棟 水素製造 */
  const fcBldWe = computed<serieData | null>(() => mapper('fcBld', { x: 2, y: 0 }))
  /** fc棟 使用電力: 受電 + 太陽光発電 + 蓄電池 - 水素製造 */
  const fcBldPowerConsumption = computed<serieData | null>(() => {
    const res = calculatePowerConsumption(fcBldPower, fcBldPv1, ref(null), fcBldBt, fcBldWe)
    return res
  })

  Logger.end(`${tsName}#uiSeriesStore`)
  return {
    allBldPower,
    allBldPv1,
    allBldPv2,
    allBldBt,
    allBldWe,
    allBldPowerConsumption,
    jimuBldPower,
    jimuBldPv1,
    jimuBldPowerConsumption,
    fcBldPower,
    fcBldPv1,
    fcBldBt,
    fcBldWe,
    fcBldPowerConsumption,
  }
}

export class Helper {
  /**
   * 二分法で配列を検索、最も近い左の値と右の値を戻す。距離が0の場合(hitの場合)、左=右=hit値を戻す
   * @param arr: serieData(時間順、arr[l].x < arr[r].x)
   * @param l: left index
   * @param r: right index
   */
  public static binarySearch(
    arr: serieData,
    time: number,
    l = 0,
    r: number = arr.length - 1
  ): { l: number; r: number } | null {
    if (l > r) {
      const err = new Error('binarySearch: wrong usage')
      Logger.error(`${tsName}#Helper.binarySearch`, '', err)
      throw err
    }
    const m = Math.floor((l + r) / 2)
    /**
     * 1. l.time > time || time > r.time
     * 2. l === r && l.time !== time
     */
    if (arr[l].x > time || arr[r].x < time) {
      return null
    }

    /**
     * 1. m.time === time
     * 2. l === r && l.time === time
     */
    if (arr[m].x === time) {
      return { l: m, r: m }
    }

    /**
     * 1. l + 1 === r (&& l.time < time < r.time)
     */
    if (l === m) {
      return { l, r }
    }

    /** l.time < time < m.time */
    if (arr[m].x > time) {
      return Helper.binarySearch(arr, time, l, m)
    }
    /** m.time < time < r.time */
    return Helper.binarySearch(arr, time, m, r)
  }

  /**
   * arrを検索し、時間においてtimeに最も近い値を二個選出し(lObj.x < time < rObj.x)、
   * (lObj.x, lObj.y), (rObj.x, rObj.y)とtimeでapproximateValueを算出
   */
  public static searchAndPredictValue(arr: serieData, time: number): number | null {
    const indexes = Helper.binarySearch(arr, time)
    if (!indexes) {
      return null
    }
    const lObj = arr[indexes.l]
    const rObj = arr[indexes.r]
    if (!lObj || !rObj || !lObj.y || !rObj.y) {
      return null
    }
    /** (x1, y1), (x2, y2)で(time, approximateValue)を算出 */
    if (rObj.y === lObj.y) {
      return lObj.y
    }
    const c = (lObj.y * rObj.x - lObj.x * rObj.y) / (rObj.y - lObj.y)
    return (lObj.y * (c + time)) / (c + lObj.x)
  }

  /**
   * 使用電力を計算する（使用電力 = 受電 + 太陽光発電 + 蓄電池 - 水素製造）
   */
  public static calculatePowerConsumption(
    power: ComputedRef<serieData | null>,
    pv1: ComputedRef<serieData | null>,
    pv2: ComputedRef<serieData | null>,
    bt: ComputedRef<serieData | null>,
    we: ComputedRef<serieData | null>
  ) {
    /** list all time */
    const v1 = power.value
    const v2a = pv1.value
    const v2b = pv2.value
    const v3 = bt.value
    const v4 = we.value
    /**
     * データがstoreに存在する時のみpowerConsumptionの中に算入される, Map<Object, coefficient>,
     * powerConsumption = Sum(ObjectVal * coefficent)
     */
    const availableDataSeries = new Map<serieData | null, number>()
    if (v1 && v1.length > 0) {
      availableDataSeries.set(v1, 1)
    }
    if (v2a && v2a.length > 0) {
      availableDataSeries.set(v2a, 1)
    }
    if (v2b && v2b.length > 0) {
      availableDataSeries.set(v2b, 1)
    }
    if (v3 && v3.length > 0) {
      availableDataSeries.set(v3, 1)
    }
    if (v4 && v4.length > 0) {
      availableDataSeries.set(v4, -1)
    }
    const timesMap = new Map<serieData | null, serieData[number]['x'][]>()
    for (const serie of availableDataSeries.keys()) {
      timesMap.set(serie, serie ? serie.map((e) => e.x).sort() : [])
    }
    /** 「Storeの中にデータが存在する」計算項目ら(v1, v2a, v2b, v3, v4)の中に、「いずれの項目もデータ」が存在する時間のupperbound と lowerbound. */
    let latestedAllDataSyncedTime = Number.MAX_SAFE_INTEGER
    let firstAllDataSyncedTime = 0
    for (const times of timesMap.values()) {
      /** times.length > 0 */
      const lastUpdatedTime = times[times.length - 1]
      const firstUpdatedTime = times[0]
      latestedAllDataSyncedTime =
        lastUpdatedTime < latestedAllDataSyncedTime ? lastUpdatedTime : latestedAllDataSyncedTime
      firstAllDataSyncedTime = firstAllDataSyncedTime < firstUpdatedTime ? firstUpdatedTime : firstAllDataSyncedTime
    }

    /** firstAllDataSyncedTimeより過去の時間、及びlastUpdatedTimeMinimumより未来の時間を除外する */
    for (const [key, times] of timesMap.entries()) {
      const filteredTimes = times.filter((t) => {
        return firstAllDataSyncedTime <= t && t <= latestedAllDataSyncedTime
      })
      timesMap.set(key, filteredTimes)
    }

    const powerConsumptionTimesUniq = new Set<number>()
    for (const t of timesMap.values()) {
      t.forEach((e) => powerConsumptionTimesUniq.add(e))
    }

    const powerConsumptionTimesUniqSortedArr = [...powerConsumptionTimesUniq].sort()
    const out: serieData = []
    for (const t of powerConsumptionTimesUniqSortedArr) {
      const vals: number[] = []
      /** power, pv, bt, weなど、引数の中にいる、且つデータも「存在」する項目を用いてpowerConsumptionを計算する。 */
      availableDataSeries.forEach((coefficent, dataSet) => {
        const val = dataSet ? Helper.searchAndPredictValue(dataSet, t) || 0 : 0
        vals.push(coefficent * val)
      })
      const yVal = vals.reduce((a, b) => a + b)

      out.push({
        x: t,
        /**
         * Sum(vals) === y1 + y2a + y2b + y3 - y4
         * 使用電力が0より小さい場合、0にする。
         */
        y: yVal > 0 ? yVal : 0,
      })
    }
    return out
  }

  /*
   * グラフのデータをマージします。
   * usecase:
   * mergeManySeries([data1, data2])
   */
  public static mergeManySeries(input: (serieData | null)[]): serieData {
    type idSeries = {
      x: number
      y: number | null
      id: number
    }

    const datas: serieData[] = new Array(input.length)

    for (let i = 0; i < input.length; i++) {
      datas[i] = input[i] ?? new Array(0)
    }

    const dataLength: number = datas.reduce((total: number, value: serieData) => {
      return total + value.length
    }, 0)

    const sortedData: idSeries[] = new Array(dataLength)
    // init data
    {
      let allindex = 0
      for (let i = 0; i < datas.length; i++) {
        for (let j = 0; j < datas[i].length; j++) {
          sortedData[allindex] = {
            x: datas[i][j].x,
            y: datas[i][j].y,
            id: i,
          }
          allindex++
        }
      }
      sortedData.sort((l, r) => {
        return l.x - r.x
      })
    }

    const before: idSeries[] = new Array(datas.length)

    for (let i = 0; i < datas.length; i++) {
      before[i] = {
        x: -1,
        y: null,
        id: i,
      }
    }

    const margedData: serieData = new Array(dataLength)
    let margedIndex = 0
    for (let i = 0; i < dataLength; margedIndex++) {
      const nowx = sortedData[i].x
      while (nowx === sortedData[i].x) {
        before[sortedData[i].id] = sortedData[i]
        i++
        if (i >= dataLength) break
      }
      margedData[margedIndex] = before.reduce(
        (ret, value) => {
          return {
            x: ret.x,
            y: ret.y + (value.y ?? 0),
          }
        },
        { x: nowx, y: 0 }
      )
    }

    return margedData.slice(0, margedIndex)
  }
}

export default uiSeriesStore
export type uiSeriesStoreReturnType = ReturnType<typeof uiSeriesStore>
export const uiSeriesStoreInjectionKey: InjectionKey<uiSeriesStoreReturnType> = Symbol('uiSeriesStore')

import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { toUtf8, fromUtf8 } from '@aws-sdk/util-utf8-browser'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { Auth } from '@aws-amplify/auth'
import { Logger } from '@/util/logger'
import awsconfig from '../../aws-exports'
const serviceName = 'store/lambda/lambdaService.ts'

export type RDSRecord = {
  date: string
  timestamp: string
  comid: string
  itemid: string
  avg: number
}

const service = {
  /**
   * lambdaを経由して、RDSへのクエリを発行する
   */
  async invoke(lambdaClient: LambdaClient, lambdaParameter: any) {
    let rtn: RDSRecord[] = []
    try {
      Logger.info(
        'lambdaService',
        'lambda_client_expire_datetime: ' + (await lambdaClient.config.credentials()).expiration?.toLocaleString() || ''
      )
      const data = await lambdaClient.send(
        new InvokeCommand({
          FunctionName: 'App_Lambda_QUERY_RDS_AURORA',
          Payload: fromUtf8(JSON.stringify(lambdaParameter)),
        })
      )
      if (data.Payload) {
        const { statusCode, body } = JSON.parse(toUtf8(data.Payload))
        if (statusCode === 200) {
          rtn = body
        } else {
          Logger.error(serviceName, body)
        }
      }
    } catch (error) {
      Logger.error(serviceName, 'error when invoking lambda', error)
      location.reload()
    }
    return rtn
  },

  /**
   * AWS.CognitoIdentityCredentialsを組む
   */
  async generateAuth() {
    const loginMapKey = `cognito-idp.${awsconfig.aws_cognito_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`
    const loginMapUserIdToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    return new LambdaClient({
      region: awsconfig.aws_cognito_region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: awsconfig.aws_cognito_region }),
        identityPoolId: awsconfig.aws_cognito_identity_pool_id,
        logins: { [loginMapKey]: loginMapUserIdToken },
      }),
    })
  },
}

export default service

import { InjectionKey, reactive, set, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import _cloneDeep from 'lodash/cloneDeep'
import { Logger } from '@/util/logger'
import { DISPLAYUI_ICONS } from '@/components/displayui/DISPLAY-UI-CONST'

const tsName = 'components/displayUiChartDataStore/displayUiChartDataStore.ts'

export const CHART_TYPE = { PLANVALUE: 0, ACTUALVALUE: 1 }
export type serieData = {
  /** unixTime */
  x: number
  /** number | null */
  y: number | null
}[]
export type graphType = 'line' | 'area' | 'column' | 'bar' | 'scatter' | 'bubble'

export type serie = {
  name: string
  type: graphType
  data: serieData
}
function DisplayUIChartDataStore() {
  Logger.start(`${tsName}#DisplayUIChartDataStore`)
  /** seriesの初期値
   *  x: 日時(number) / y: 値(number|null)
   *  yにnullを入れることでグラフの線が途切れた表示にできる
   */
  const seriesInitVal: serie = { name: '', type: 'area', data: [{ x: NaN, y: null }] }

  const startUpData = ref(dayjs().startOf('day').toDate().getTime())

  /** options(yaxis)の初期値 */
  const optYaxisInitVal = {
    show: true,
    opposite: false,
    seriesName: '',
    decimalsInFloat: 0,
    axisTicks: {
      show: true,
    },
    axisBorder: {
      show: true,
    },
    title: {
      text: '',
      offsetX: -15,
      style: {
        fontSize: '30px',
      },
    },
    labels: {
      style: {
        fontSize: '20px',
      },
    },
  }

  const chartData = reactive({
    data: {
      series: [_cloneDeep(seriesInitVal)] as serie[],
      options: {
        theme: {
          palette: 'palette6',
        },
        fill: {
          opacity: 0.9,
          gradient: {
            shadeIntensity: 0.5,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 90, 100],
          },
        },
        chart: {
          animations: {
            enabled: false,
          },
          fontFamily: 'NotoSansJP',
          height: 400,
          type: 'line',
          stacked: false,
          foreColor: '#ccc',
          toolbar: {
            show: false,
          },
          zoom: false,
        },
        colors: [...DISPLAYUI_ICONS].sort((a, b) => a.indexOfLegend - b.indexOfLegend).map((e) => e.icon.color),
        legend: {
          height: 50,
          showForSingleSeries: true,
          showForZeroSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '20px',
          markers: {
            width: 18,
            height: 18,
            radius: 2,
          },
        },
        stroke: {
          width: [2, 3],
          curve: 'straight',
        },
        markers: {
          size: 0,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          min: dayjs().startOf('day').toDate().getTime(),
          max: dayjs().endOf('day').toDate().getTime(),
          offsetY: -5,
          labels: {
            format: 'H',
            datetimeUTC: false,
            style: {
              fontSize: '20px',
            },
          },
        },
        yaxis: [_cloneDeep(optYaxisInitVal)],
        tooltip: {
          enabled: false,
          shared: true,
          intersect: false,
          followCursor: true,
          x: {
            formatter: (val: number) => {
              if (!val) {
                return 'No Data'
              }
              const date = new Date(val)
              return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
            },
          },
        },
      },
    },
  })

  function getChartData() {
    return chartData
  }

  /** reactivityを保持させるままデータを変更する。 */
  function setChartData(index: number, data: serieData, name: string, type: graphType, tglSelected: number) {
    Logger.start(`${tsName}#setChartData`)
    const series = chartData.data.series
    while (!series[index]) {
      series.push(_cloneDeep(seriesInitVal))
    }
    set(series[index], 'data', data)
    if (name !== series[index].name) {
      set(series[index], 'name', name)
    }
    if (type !== series[index].type) {
      set(series[index], 'type', type)
    }
    xChangeOption(tglSelected)
    Logger.end(`${tsName}#setChartData`)
  }

  /** データセットを切り替える時に既存のデータを消去するメソッド */
  function clearData() {
    Logger.start(`${tsName}#clearData`)
    set(chartData.data, 'series', [_cloneDeep(seriesInitVal)])
    Logger.end(`${tsName}#clearData`)
  }

  /** componentがロードされる前に、chartDataStore.optionを初期化する。 */
  function initOptions() {
    Logger.start(`${tsName}#initOptions`)
    set(chartData.data.options, 'yaxis', [_cloneDeep(optYaxisInitVal)])
    const yaxises = chartData.data.options.yaxis
    /** y軸の横文字 */
    yaxises[0].title.text = 'kW'
    Logger.end(`${tsName}#initOptions`)
  }

  function xChangeOption(selectIndex: number) {
    Logger.start(`${tsName}#xChangeOption`)
    const chartOptions = chartData.data.options
    const startData = dayjs().startOf('day').toDate().getTime()
    if (selectIndex === 0) {
      chartOptions.xaxis.min = startData
      chartOptions.xaxis.max = dayjs().endOf('day').toDate().getTime()
      chartOptions.xaxis.labels.format = 'H'
    } else {
      chartOptions.xaxis.min = dayjs().subtract(1, 'h').toDate().getTime() - 1
      chartOptions.xaxis.max = dayjs().toDate().getTime() + 1
      chartOptions.xaxis.labels.format = 'H:mm'
    }

    if (startUpData.value !== startData) {
      clearYesterday()
    }
    set(chartData.data, 'options', chartOptions)
    Logger.end(`${tsName}#xChangeOption`)
  }

  function clearYesterday() {
    const beforeOneHour = dayjs().startOf('day').subtract(1, 'h').toDate().getTime() - 1
    const tmpSeries = chartData.data.series
    // 前日の23時までのデータはクリアする
    for (let i = 0; i < tmpSeries.length; i++) {
      tmpSeries[i].data = tmpSeries[i].data.filter((series) => series.x > beforeOneHour)
    }
    clearData()
    set(chartData.data, 'series', tmpSeries)
    startUpData.value = dayjs().startOf('day').toDate().getTime()
  }
  Logger.end(`${tsName}#DisplayUIChartDataStore`)
  return {
    clearData,
    getChartData,
    initOptions,
    setChartData,
    xChangeOption,
  }
}

export default DisplayUIChartDataStore
export type DisplayUIChartDataStoreReturnType = ReturnType<typeof DisplayUIChartDataStore>
export const DisplayUIChartDataStoreInjectionKey: InjectionKey<DisplayUIChartDataStoreReturnType> = Symbol(
  'DisplayUIChartDataStore'
)
